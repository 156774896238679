import React from 'react'
import { QuoteOuter, QuoteInner } from './quote'

// Components
import Typography from '../typography'
import Button from '../button'
import QuoteBgImage from './quoteBgImage'

// Helpers
import ImgUtil from '../../../utils/img.utils'
import { internalUrl, internalLinkHandler } from '../../../utils/link.utils' 
import formatInlineCode from '../../../utils/shortTextFormatting'

type QuoteProps = {
  data: {
    __typename: string
    authorName: string
    authorTitle: string
    company: string
    name: string
    theme: string
    image: { altText?: string; image?: { file?: { url?: string } } }
    body: { body: string }
    quoteCta?: { externalUrl: string; text: string; internalLink?: internalUrl }
  }
  isPanel?: boolean
  slidePrevClick?: any
  slideNextClick?: any
  itemNum?: number
  totalItems?: number
  themeOverride?: string
}

const Quote = (props: QuoteProps) => {
  if (props.data) {
    const { image, quoteCta } = props.data
    const authorName = props.data.authorName ? props.data.authorName : ''
    const authorTitle = props.data.authorTitle ? props.data.authorTitle : ''
    const body =
      props.data.body && props.data.body.body ? props.data.body.body : ''
    const company = props.data.company ? props.data.company : ''
    let theme
    if (props.themeOverride) {
      theme = props.themeOverride
    } else if (props.data.theme) {
      theme = props.data.theme
    } else {
      theme = 'Light'
    }
    const quoteCtaText = quoteCta && quoteCta.text ? quoteCta.text : ''

    const imgAltText = image && image.altText ? image.altText : ''
    const imgUrl =
      image && image.image && image.image.file && image.image.file.url
        ? image.image.file.url
        : ''

    return (
      <QuoteOuter quoteTheme={theme} data-cy="Quote">
        <QuoteInner>
          <QuoteBgImage />
          <div className="Quote__Inner">
            <div className="Quote__Image-Container">
              <img
                loading="lazy"
                className="Quote__Image"
                src={ImgUtil.getResizedImageUrl(imgUrl, 'width=282')}
                alt={imgAltText}
              />
            </div>
            <div className="Quote__Text">
              {body && (
                <Typography type="leadXS">{formatInlineCode(body)}</Typography>
              )}
              {authorName && (
                <Typography className="Quote__Author" type="label">
                  {authorName}
                </Typography>
              )}
              {authorTitle && company && (
                <Typography
                  className="Quote__Title"
                  type="bodyXS"
                >{`${authorTitle}, ${company}`}</Typography>
              )}
              {quoteCtaText &&
                (quoteCta?.externalUrl || quoteCta?.internalLink) && (
                  <Button
                    icon={true}
                    href={
                      quoteCta.internalLink
                        ? internalLinkHandler(quoteCta.internalLink)
                        : quoteCta.externalUrl
                    }
                    text={quoteCtaText}
                    type="text-link"
                    size="small"
                    className="Quote__CTA"
                    theme={theme === 'Dark' ? 'dark' : 'light'}
                  />
                )}
            </div>
          </div>
        </QuoteInner>
      </QuoteOuter>
    )
  } else {
    return null
  }
}

export default Quote
