import React from 'react'
import styled from 'styled-components'

// Components
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

// Styles
import * as styles from './style'

// Images
import CloseIcon from '../../../../static/steam-img/Close-White.svg'
import Logo from '../../../../static/steam-img/UiPath-full-logo-white.svg'

type ModalProps = {
  open: boolean
  handleClose: any
  children: any
  type: string
}

export default function DottedModal(props: ModalProps) {
  const videoContainer = React.useRef(null)
  const handleXClick = (e: any) => {
    e.preventDefault()
    window.removeEventListener('resize', updateIframeDimensions)
    props.handleClose()
  }

  const addEvents = () => {
    window.addEventListener('resize', updateIframeDimensions)
    updateIframeDimensions()
  }

  const updateIframeDimensions = () => {
    let height
    let width
    if (videoContainer.current) {
      // 81px for modal header section
      const windowHeight = window.innerHeight - 81
      const windowWidth = window.innerWidth
      const windowAspect = windowHeight / windowWidth

      //add 32 px to width to accommodate for 16px margins
      const [dimensionWidth, dimensionHeight] = [1232, 693]
      const [dimensionWidthMobile, dimensionHeightMobile] = [768, 432]

      const iframeAspect = dimensionHeight / dimensionWidth
      const iframeAspectMobile = dimensionHeightMobile / dimensionWidthMobile

      let r = iframeAspect,
        h = dimensionHeight,
        w = dimensionWidth

      if (window.matchMedia('(max-width: 1280px)').matches) {
        r = iframeAspectMobile
        h = dimensionHeightMobile
        w = dimensionWidthMobile
      }

      /*
        When the window height is relatively smaller, iframe aspect would be greater.
        Minus 32 for 16px margins around.
      */
      if (r > windowAspect) {
        width = Math.min(h, windowHeight) / r - 32
        height = width * r
      } else {
        width = Math.min(w, windowWidth) - 32
        height = width * r
      }

      videoContainer.current.style.cssText += `width:${width}px;height:${height}px;`
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={handleXClick}
      closeAfterTransition
      onRendered={addEvents}
      BackdropComponent={styles.CustomBackdrop}
      BackdropProps={{
        timeout: 250,
      }}
      data-cy="DottedModal"
    >
      <Fade in={props.open}>
        <React.Fragment>
          <styles.ModalHeader>
            <img src={Logo} />
            <div href="#" onClick={props.handleClose}>
              <img
                src={CloseIcon}
                alt="Close Icon"
                className="Modal-Close-Icon"
              />
            </div>
          </styles.ModalHeader>
          <styles.ModalOuter
            type={props.type}
            data-cy="ModalOuter"
            onClick={handleXClick}
          >
            <div className="Dotted-Modal__Content-Container">
              {props.type === 'video' ? (
                <styles.VideoModalInner ref={videoContainer}>
                  {props.children}
                </styles.VideoModalInner>
              ) : (
                props.children
              )}
            </div>
          </styles.ModalOuter>
        </React.Fragment>
      </Fade>
    </Modal>
  )
}
