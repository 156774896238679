import React from 'react'

// Styles
import {
  Label,
  LabelAlt,
  Lead,
  LeadXS,
  Body,
  BodyXS,
  BodyXSWrapper,
  Header1,
  Header2,
  Header3,
  Header4,
  Header5,
  Header6,
  Display,
  DisplayXL,
  Caption,
  CaptionAlt,
  MicroAlt,
} from './typography'

type TypographyProps = {
  type:
    | 'microAlt'
    | 'caption'
    | 'captionAlt'
    | 'label'
    | 'labelAlt'
    | 'lead'
    | 'leadXS'
    | 'body'
    | 'bodyXS'
    | 'bodyXSWrapper'
    | 'header1'
    | 'header2'
    | 'header3'
    | 'header4'
    | 'header5'
    | 'header6'
    | 'display'
    | 'displayXL'
  children?: any
  className?: string
}

const Typography = (props: TypographyProps) => {
  switch (props.type) {
    //Micro
    case 'microAlt':
      return <MicroAlt className={props.className}>{props.children}</MicroAlt>

    // Captions
    case 'caption':
      return <Caption className={props.className}>{props.children}</Caption>
    case 'captionAlt':
      return (
        <CaptionAlt className={props.className}>{props.children}</CaptionAlt>
      )

    // Labels
    case 'label':
      return <Label className={props.className}>{props.children}</Label>
    case 'labelAlt':
      return <LabelAlt className={props.className}>{props.children}</LabelAlt>

    // Lead
    case 'leadXS':
      return <LeadXS className={props.className}>{props.children}</LeadXS>
    case 'lead':
      return <Lead className={props.className}>{props.children}</Lead>

    // Body
    case 'body':
      return <Body className={props.className}>{props.children}</Body>
    case 'bodyXS':
      return <BodyXS className={props.className}>{props.children}</BodyXS>
    case 'bodyXSWrapper':
      return (
        <BodyXSWrapper className={props.className}>
          {props.children}
        </BodyXSWrapper>
      )

    // Headers
    case 'header1':
      return <Header1 className={props.className}>{props.children}</Header1>
    case 'header2':
      return <Header2 className={props.className}>{props.children}</Header2>
    case 'header3':
      return <Header3 className={props.className}>{props.children}</Header3>
    case 'header4':
      return <Header4 className={props.className}>{props.children}</Header4>
    case 'header5':
      return <Header5 className={props.className}>{props.children}</Header5>
    case 'header6':
      return <Header6 className={props.className}>{props.children}</Header6>

    // Display
    case 'display':
      return <Display className={props.className}>{props.children}</Display>
    case 'displayXL':
      return <DisplayXL className={props.className}>{props.children}</DisplayXL>
  }
}

export default Typography
