import styled from 'styled-components'

// Micro
export const MicroAlt = styled.p`
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.microALT[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.microALT[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.microALT[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.microALT[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    `
  }}
`

// Captions
export const Caption = styled.p`
  font-family: 'Inter', sans-serif;

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.caption[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.caption[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.caption[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.caption[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    `
  }}
`

export const CaptionAlt = styled.p`
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.captionAlt[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.captionAlt[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.captionAlt[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.captionAlt[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    `
  }}
`

// Labels
export const Label = styled.p`
  font-family: 'Inter', sans-serif;
  color: rgb(0, 0, 0);

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.label[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.label[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.label[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.label[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    `
  }}
`

export const LabelAlt = styled.p`
  font-family: 'Inter', sans-serif;
  color: rgb(0, 0, 0);
  text-transform: uppercase;

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.labelAlt[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.labelAlt[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.labelAlt[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.labelAlt[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    `
  }}
`

// Lead
export const Lead = styled.p`
  font-family: 'Inter', sans-serif;
  color: rgb(0, 0, 0);

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.lead[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.lead[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.lead[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.lead[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    `
  }}
`

export const LeadXS = styled.p`
  font-family: 'Inter', sans-serif;
  color: rgb(0, 0, 0);

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.leadXS[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.leadXS[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.leadXS[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.leadXS[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    p,
    ul,
    li {
      color: rgb(0, 0, 0);
      ${generateStyles('lg')}
      ${p.theme.breakpoints.down('md')} {
        ${generateStyles('md')}
      }
      ${p.theme.breakpoints.down('sm')} {
        ${generateStyles('sm')}
      }
    }
    `
  }}
`

// Body
export const Body = styled.p`
  font-family: 'Inter', sans-serif;
  color: rgb(0, 0, 0);

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.body[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.body[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.body[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.body[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    p,
    ul,
    li {
      color: rgb(0, 0, 0);
      ${generateStyles('lg')}
      ${p.theme.breakpoints.down('md')} {
        ${generateStyles('md')}
      }
      ${p.theme.breakpoints.down('sm')} {
        ${generateStyles('sm')}
      }
    }
    `
  }}
`

export const BodyXS = styled.p`
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: rgb(0, 0, 0);

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.bodyXS[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.bodyXS[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.bodyXS[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.bodyXS[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    `
  }}
`

export const BodyXSWrapper = styled.div`
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: rgb(0, 0, 0);

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.bodyXS[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.bodyXS[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.bodyXS[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.bodyXS[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    p,li {
      margin: inherit;
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      letter-spacing: inherit;
      font-family: inherit;
    }
    `
  }}
`

// Headers
export const Header1 = styled.h1`
  font-family: 'Inter', sans-serif;
  color: rgb(0, 0, 0);

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.heading1[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.heading1[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.heading1[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.heading1[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    `
  }}
`
export const Header2 = styled.h2`
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: rgb(0, 0, 0);

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.heading2[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.heading2[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.heading2[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.heading2[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    `
  }}
`
export const Header3 = styled.h3`
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: rgb(0, 0, 0);

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.heading3[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.heading3[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.heading3[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.heading3[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    `
  }}
`
export const Header4 = styled.h4`
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: rgb(0, 0, 0);

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.heading4[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.heading4[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.heading4[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.heading4[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    `
  }}
`
export const Header5 = styled.h5`
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: rgb(0, 0, 0);

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.heading5[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.heading5[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.heading5[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.heading5[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    `
  }}
`
export const Header6 = styled.h6`
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: rgb(0, 0, 0);

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.heading6[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.heading6[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.heading6[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.heading6[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    `
  }}
`

// Display
export const Display = styled.p`
  font-family: 'Inter', sans-serif;

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.display[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.display[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.display[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.display[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    `
  }}
`

export const DisplayXL = styled.p`
  font-family: 'Inter', sans-serif;

  ${(p) => {
    const generateStyles = (breakpoint) => {
      return `
      font-size: ${p.theme.webMktTypography.typography.displayXL[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.displayXL[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.displayXL[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.displayXL[breakpoint].lineHeight};
    `
    }
    return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    `
  }}
`
