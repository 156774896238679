import styled from 'styled-components'
import search from '../../../../static/steam-img/search.svg'

export const MediaTextContainer = styled.div`
  ${(p) => {
    let shadowOptions = ``
    if (p.mediaStyle !== 'No Drop Shadow')
      shadowOptions += `
        .MediaText__Mask-Wrapper img, .MediaText__Mask-Wrapper video {
          box-shadow: 0px 40px 24px -24px rgba(0, 0, 0, 0.15);
          ${p.theme.breakpoints.down('xs')} {
            box-shadow: 0px 20px 12px -12px rgba(0, 0, 0, 0.15);
          }
        }
      `

    let paddingOptions = ``
    if (p.topPadding === 'Large') paddingOptions += 'padding-top: 96px;'
    else if (p.topPadding === 'Small') paddingOptions += 'padding-top: 64px;'
    if (p.bottomPadding === 'Large') paddingOptions += 'padding-bottom: 96px;'
    else if (p.bottomPadding === 'Small')
      paddingOptions += 'padding-bottom: 64px;'

    let themeStyles = ``
    if (p.background === 'Dark') {
      themeStyles += `
        background: ${p.theme.webMktPalette.background.dark};
        .MediaText__Mask-Wrapper {
          &:before {
            background-image: radial-gradient(white 1px, transparent 0);
          }
        }

        .MediaText__Text-Container {
          .MediaText__Heading,
          .MediaText__Body li,
          .MediaText__Body p {
            color: rgb(255, 255, 255);
          }
        }
      `
    } else {
      themeStyles += `
        background: ${
          p.background === 'Light'
            ? p.theme.webMktPalette.background.white
            : p.theme.webMktPalette.background.gray
        };
        .MediaText__Mask-Wrapper {
          &:before {
            background-image: radial-gradient(black 1px, transparent 0);
          }
        }
        .MediaText__Text-Container {
          .MediaText__Heading,
          .MediaText__Body li,
          .MediaText__Body p {
            color: rgb(0, 0, 0);
          }
        }
      `
    }

    let alignmentStyles = ``
    let gridLgBottom = p.mediaStyle === 'No Drop Shadow' ? '24px' : '50px'
    let gridMdBottom = p.mediaStyle === 'No Drop Shadow' ? '16px' : '40px'
    let gridSmBottom = p.mediaStyle === 'No Drop Shadow' ? '3px' : '30px'

    alignmentStyles += `
      .MediaText__Image-Container {
        padding-bottom: ${gridLgBottom};
        ${p.theme.breakpoints.down('md')} {
          padding-bottom: ${gridMdBottom};
        }
        ${p.theme.breakpoints.down('md')} {
          padding-bottom: ${gridSmBottom};
        }
      }
    `
    if (p.alignment === 'left') {
      alignmentStyles += `
        .MediaText__Outer-Container {
          justify-content: flex-end;
          display: flex;
        }
        .MediaText__Inner-Container {
          flex-direction: row;
        }
        .MediaText__Image-Container {
          text-align: right;

          .MediaText__Mask-Wrapper {
            margin-left: auto;
            padding-left: 64px;
            &:before {
              left: 0px;
              bottom: -${gridLgBottom};
            }
          }

          margin-right: 126px;
          ${p.theme.breakpoints.down('md')} {
            margin-right: 106px;
             .MediaText__Mask-Wrapper {
              padding-left: 48px;
              &:before {
                bottom: -${gridMdBottom};
                left: 0px;
              }
            }
          }
          ${p.theme.breakpoints.down('sm')} {
            margin-right: 0;
             .MediaText__Mask-Wrapper {
              padding-left: 0px;
              &:before {
                bottom: -${gridSmBottom};
                left: -40px;
              }
            }
          }
        }
      `
    } else {
      alignmentStyles += `
        .MediaText__Inner-Container {
          flex-direction: row-reverse;
        }
        .MediaText__Image-Container {
          margin-left: 126px;

          .MediaText__Mask-Wrapper {
            padding-right: 64px;
            &:before {
              bottom: -${gridLgBottom};
              right: 0px;
            }
          }


          ${p.theme.breakpoints.down('md')} {
            margin-left: 106px;
            .MediaText__Mask-Wrapper {
              padding-right: 48px;
              &:before {
                bottom: -${gridMdBottom};
                right: 0px;
              }
            }
          }
          ${p.theme.breakpoints.down('sm')} {
            margin-left: 0;
            .MediaText__Mask-Wrapper {
              padding-right: 0px;
              &:before {
                bottom: -${gridSmBottom};
                right: -40px;
              }
            }
          }
        }
      `
    }

    let enableClick = ``
    if (p.enableClickToEnlarge) {
      enableClick += `
        .MediaText__Mask--None,
        .MediaText__Mask--Image,
        .MediaText__Mask--Video {
          &:after {
            content: '';
            position: absolute;
            width: 48px;
            bottom: 0;
            right: 0;
            z-index: 5;
            background-repeat: no-repeat;
            height: 48px;
            background-position: center;
            background-color: white;
            background-size: 24px 24px;
            background-image: url(${search});
          }
        }
      `
    }

    const customSMBreakpoint = '1130px'
    const custommMDLGBreakpoint = '1400px'

    return `
      ${themeStyles}
      ${enableClick}
      ${alignmentStyles}
      ${paddingOptions}
      ${shadowOptions}

      .MediaText__Inner-Container {
        width: 1367px;
        align-items: center;
        display: flex;
        flex: none;
        justify-content: space-between;
        ${p.theme.breakpoints.down('md')} {
          width: 1090px;
        }

        @media screen and (max-width: ${customSMBreakpoint}) {
          width: 100%;
          align-items: center;
          flex-direction: column;
          margin: 0 auto;
        }

        ${p.theme.breakpoints.down('xs')} {
          .MediaText__Text-Container {
            padding: 32px 0 0 0;
            &:before {
              content: none;
            }
          }
          .MediaText__Image-Container {
            padding: 0;
          }
        }
      }

      .MediaText__Image-Container {
        width: 100%;
        box-sizing: border-box;
        border-radius: 0px;
        position: relative;
        max-width: 754px;

        .MediaText__Mask--None,
        .MediaText__Mask--Image,
        .MediaText__Mask--Video  {
          position: relative;
        }

        .MediaText__Mask-Wrapper {
          width: 690px;
          display: flex;
          justify-content: center;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            width: 360px;
            height: 240px;
            z-index: 0;
            background-size: 24px 24px;
            ${p.theme.breakpoints.down('xs')} {
              display: none;
            }
          }

          @media screen and (max-width: ${custommMDLGBreakpoint}) {
            width: 586px;
          }
          ${p.theme.breakpoints.down('md')} {
            width: 550px;
          }
          @media screen and (max-width: ${customSMBreakpoint}) {
            width: auto;
          }
        }

        img {
          position: relative;
          z-index: 1;
        }

        .MediaText__Mask--None {
          display: inline-block;
          min-width: 50%;
          img {
            width: auto;
            height: auto;
            min-width: 100%;
          }
        }

        .MediaText__Mask--Video {
          height: auto;
        }

        video {
          max-width: 100%;
        }

        .MediaText__Mask--Image {
          width: 100%;
          img {
            height: auto;
            width: 100%;
          }

          @media screen and (max-width: ${customSMBreakpoint}) {
            width: auto;
            height: auto;
          }
        }

        .MediaText__Mask--Video,
        .MediaText__Mask--Image {
          @media screen and (max-width: ${customSMBreakpoint}) {
            max-width: 682px;
            margin: 0 auto;
            width: 100%;
          }
        }

        ${p.theme.breakpoints.down('md')} {
          max-width: 598px;
        }

        @media screen and (max-width: ${customSMBreakpoint}) {
          max-width: 682px;
          text-align: center;
        }

        ${p.theme.breakpoints.down('xs')} {
          margin: 0;
          padding: 0;
        }
      }
      .MediaText__Text-Container {
        padding-top: 64px;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        max-width: 486px;

        &:before {
          content: '';
          width: 80px;
          height: 4px;
          background: ${p.theme.webMktPalette.orange[50]};
          position: absolute;
          top: 0;
          left: 0;
          ${p.theme.breakpoints.down('xs')} {
            top: 32px;
          }
        }

        @media screen and (max-width: ${customSMBreakpoint}) {
          max-width: 548px;
          align-self: flex-start;
          &:before {
            content: none;
          }
        }
        ${p.theme.breakpoints.down('xs')} {
          padding-top: 60px;
          max-width: 100%;
        }
        .MediaText__Heading {
          margin: 0;
        }
        .MediaText__Body {
          margin-top: 24px;
          ul {
            list-style: disc;
            padding-left: 24px;
          }
          li, p {
            margin: 0;
          }

          li {
            padding-left: 8px;
            margin-top: 8px;
            > p {
              margin: 0;
            }
          }

          ul, ol, > p {
            margin-top: 24px;
          }
        }
        .CTA {
          margin-top: 32px;
        }
      }
    `
  }}
`
