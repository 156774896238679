import React from 'react'
import { PlatformDiagramOuter } from './platformDiagram'

// Components
import Typography from '../typography'
import GlobalContainer from '../globalContainer'

// Helpers
import ImgUtil from '../../../utils/img.utils'
import { isExternalURL, createLinkBasedOnType } from '../../../utils/link.utils'
import formatInlineCode from '../../../utils/shortTextFormatting'

// Images
import ArrowRight from '../../../../static/steam-img/arrows/ArrowLong_Right-Grey-30.svg'

type PlatformDiagramProps = any

const PlatformDiagram = (props: PlatformDiagramProps) => {
  if (props.data) {
    const headline = props.data.headline ? props.data.headline : ''
    const itemDescription = props.data.itemDescription
      ? props.data.itemDescription
      : ''

    const iconTextItems = props.data.items ? props.data.items : undefined
    const productLocalizedGroupNames = ['Product', 'Produkt']

    const navGroupItems =
      props.data.menuGroup &&
      props.data.menuGroup.menuGroups &&
      props.data.menuGroup.menuGroups
        ? props.data.menuGroup.menuGroups.filter(
            (menuGroup: any) =>
              productLocalizedGroupNames.indexOf(menuGroup.groupName) !== -1
          )
        : undefined

    return (
      <GlobalContainer>
        <PlatformDiagramOuter data-cy="PlatformDiagram">
          <div className="PlatformDiagram__Top-Outer">
            <div className="PlatformDiagram__Heading-Container">
              <span className="PlatformDiagram__Heading-Orange-Line"></span>
              <Typography className="PlatformDiagram__Heading" type="header2">
                {formatInlineCode(headline)}
              </Typography>
            </div>
            <div className="PlatformDiagram__Top-Grid"></div>
          </div>
          <div className="PlatformDiagram__Navigation-Group">
            {navGroupItems &&
              navGroupItems[0] &&
              navGroupItems[0].pages &&
              navGroupItems[0].pages.map((group: any, i: number) => {
                let labelColor
                switch (group.groupName) {
                  case 'Products':
                    labelColor = 'black'
                    break
                  case 'Discover':
                    labelColor = 'red'
                    break
                  case 'Build':
                    labelColor = 'blue'
                    break
                  case 'Manage':
                    labelColor = 'orange'
                    break
                  case 'Run':
                    labelColor = 'green'
                    break
                  case 'Engage':
                    labelColor = 'purple'
                    break
                }

                return (
                  <div
                    className="PlatformDiagram__Nav-Item"
                    key={`menuGroup--${i}`}
                  >
                    <Typography
                      className={`PlatformDiagram__Nav-Item-Heading Menu-container-label--${labelColor}`}
                      type="header6"
                    >
                      {group.groupName}
                    </Typography>
                    <Typography
                      className="PlatformDiagram__Nav-Item-Description"
                      type="bodyXS"
                    >
                      {group.menuGroupDescription}
                    </Typography>
                    {group.pages &&
                      group.pages.map((page: any, k: number) => {
                        let target
                        const parentLinkHref = createLinkBasedOnType(page)
                        if (isExternalURL(parentLinkHref)) {
                          target = '_blank'
                        } else {
                          target = '_self'
                        }
                        return (
                          <a
                            href={parentLinkHref}
                            key={`menuGroupPage--${k}`}
                            target={target}
                            className="PlatformDiagram__Nav-Item-Link"
                          >
                            {page.text}
                          </a>
                        )
                      })}
                  </div>
                )
              })}
          </div>
          <div className="PlatformDiagram__Bottom-Items">
            <Typography
              className="PlatformDiagram__Bottom-Heading"
              type="header5"
            >
              {itemDescription}
            </Typography>
            <div className="PlatformDiagram__Bottom-Grid">
              {iconTextItems &&
                iconTextItems.map((iconText: any, n: number) => {
                  const imgSrc =
                    iconText.colorIcon &&
                    iconText.colorIcon.file &&
                    iconText.colorIcon.file.url
                      ? iconText.colorIcon.file.url
                      : ''

                  const imgAlt = iconText.seo ? iconText.seo : 'UiPath Icon'
                  const iconTextHeading = iconText.title ? iconText.title : ''
                  let showArrow = n !== iconTextItems.length - 1
                  return (
                    <>
                      <div
                        className="PlatformDiagram__Icon-Text"
                        key={`iconTextItem--${n}`}
                      >
                        <div className="PlatformDiagram__Icon-Text-Image-Container">
                          <img
                            className="PlatformDiagram__Icon-Text-Image"
                            src={imgSrc}
                            alt={imgAlt}
                          />
                        </div>
                        <Typography
                          className="PlatformDiagram__Icon-Text-Copy"
                          type="label"
                        >
                          {iconTextHeading}
                        </Typography>
                      </div>
                      {showArrow && (
                        <img
                          src={ArrowRight}
                          alt="Arrow Right"
                          className="PlatformDiagram__Icon-Arrow"
                        />
                      )}
                    </>
                  )
                })}
            </div>
          </div>
        </PlatformDiagramOuter>
      </GlobalContainer>
    )
  } else {
    return null
  }
}

export default PlatformDiagram
