import React from 'react'
import {
  VideoSpotlightContainer,
  ProductPageVideoContainer,
  VidWrapper,
} from './style'
import VideoPlayer from '../videoPlayer'
import Button from '../button'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import options from '../../../utils/richText.utils'

// Helpers
import ImgUtil from '../../../utils/img.utils'
import formatInlineCode from '../../../utils/shortTextFormatting'
import { internalUrl, internalLinkHandler } from '../../../utils/link.utils' 

const VideoSpotlight = (props: any) => {
  if (props.data) {
    let theme: string = 'Light',
      headline: string = '',
      subheadline: string = '',
      body: any,
      cta: { externalUrl: string; text: string; internalLink?: internalUrl} | undefined,
      logo: string = '',
      logoAltText: string = '',
      videoId: string = '',
      videoBackground: string = '',
      videoBackgroundAltText: string = '',
      topPadding: string = 'Large',
      bottomPadding: string = 'Large',
      useCase: string = '',
      start: string = ''

    if (props.data.theme) theme = props.data.theme

    if (props.data.headline) headline = props.data.headline
    if (props.data.body) body = props.data.body
    if (props.data.spotlightCta) cta = props.data.spotlightCta
    if (props.data.subheadline && props.data.subheadline.subheadline)
      subheadline = props.data.subheadline.subheadline
    if (props.data.topPadding) topPadding = props.data.topPadding
    if (props.data.bottomPadding) bottomPadding = props.data.bottomPadding
    if (props.data.logo) {
      if (props.data.logo.altText) logoAltText = props.data.logo.altText
      if (
        props.data.logo.image &&
        props.data.logo.image.file &&
        props.data.logo.image.file.url
      )
        logo = props.data.logo.image.file.url
    }
    if (props.data.video) {
      if (props.data.video.youtubeId) videoId = props.data.video.youtubeId
      if (props.data.video.vimeoId) videoId = props.data.video.vimeoId
      if (props.data.video.altText)
        videoBackgroundAltText = props.data.video.altText
      if (
        props.data.video.image &&
        props.data.video.image.file &&
        props.data.video.image.file.url
      )
        videoBackground = props.data.video.image.file.url
      if (props.data.video.start) start = props.data.video.start
    }
    if (props.useCase) useCase = props.useCase

    const hasTopSection = logo || headline || subheadline
    return (
      <VideoSpotlightContainer
        background={useCase && useCase === 'Resource' ? 'Light' : theme}
        topPadding={useCase && useCase === 'Resource' ? 'None' : topPadding}
        useCase={useCase}
        data-cy="VideoSpotlight"
      >
        {hasTopSection && (
          <div className="VideoSpotlight__Content-Container">
            {logo && (
              <div className="VideoSpotlight__Logo-Container">
                <img
                  src={ImgUtil.getResizedImageUrl(logo, 'width=575')}
                  loading="lazy"
                  alt={logoAltText}
                />
              </div>
            )}
            {headline && (
              <h3 className="VideoSpotlight__Headline">
                {formatInlineCode(headline)}
              </h3>
            )}
            {subheadline && (
              <p className="VideoSpotlight__Subheadline">
                {formatInlineCode(subheadline)}
              </p>
            )}
          </div>
        )}
        <ProductPageVideoContainer bottomPadding={bottomPadding}>
          {videoId && (
            <VidWrapper>
              <VideoPlayer
                width={'100%'}
                height="429"
                videoId={videoId}
                type={
                  videoId && props.data.video.youtubeId ? 'youtube' : 'vimeo'
                }
                img={videoBackground}
                backgroundAltText={videoBackgroundAltText}
                backgroundImgParams={'width=732'}
                start={start}
              />
            </VidWrapper>
          )}

          {(cta || body) && (
            <div className="VideoSpotlight__Content-Container">
              {body && (
                <div className="VideoSpotlight__Body">
                  {renderRichText(body, options)}
                </div>
              )}
              {cta && (
                <Button
                  icon={true}
                  href={
                    cta.internalLink
                      ? internalLinkHandler(cta.internalLink)
                      : cta.externalUrl
                  }
                  text={cta.text}
                  type="text-link"
                  size="small"
                  theme={theme.toLowerCase()}
                />
              )}
            </div>
          )}
        </ProductPageVideoContainer>
      </VideoSpotlightContainer>
    )
  }
  return null
}
export default VideoSpotlight
