export const pageSectionMap = {
  blog: {
    '/blog': { name: 'Blog', sectionUrl: '/blog' },
    '/blog/rpa': { name: 'RPA', sectionUrl: '/blog/rpa' },
    '/blog/robotic-process-automation': {
      name: 'RPA',
      sectionUrl: '/blog/rpa',
    },
    '/blog/ai': { name: 'Artificial Intelligence', sectionUrl: '/blog/ai' },
    '/blog/artificial-intelligence': {
      name: 'Artificial Intelligence',
      sectionUrl: '/blog/ai',
    },
    '/blog/industry-solutions': {
      name: 'Industry Solutions',
      sectionUrl: '/blog/industry-solutions',
    },
    '/blog/product-and-updates': {
      name: 'Product',
      sectionUrl: '/blog/product-and-updates',
    },
    '/blog/digital-transformation': {
      name: 'Digital Transformation',
      sectionUrl: '/blog/digital-transformation',
    },
    '/blog/automation': {
      name: 'Automation',
      sectionUrl: '/blog/automation',
    },
  },
  global: {
    '/product': { name: 'Product', sectionUrl: '/product' },
    '/solutions': {
      name: 'Solutions',
      sectionUrl: '/solutions',
    },
    '/solutions/department': {
      name: 'By Department',
      sectionUrl: '/solutions/department',
    },
    '/solutions/technology': {
      name: 'By Technology',
      sectionUrl: '/solutions/technology',
    },
    '/resources': {
      name: 'Resources',
      sectionUrl: '/resources/all',
    },
    '/company': {
      name: 'Company',
      sectionUrl: '/company/about-us',
    },
    '/resources/automation-demo': {
      name: 'Automation Demo',
      sectionUrl: '/resources/automation-demo',
    },
    '/resources/automation-case-studies': {
      name: 'Automation Case Studies',
      sectionUrl: '/resources/automation-case-studies',
    },
    '/rpa/journey': { name: 'RPA Journey', sectionUrl: '/rpa/journey' },
    '/partners': {
      name: 'Partners',
      sectionUrl: '/partners',
    },
    '/partners/business-partners': {
      name: 'Find a partner',
      sectionUrl: '/partners/business-partners',
    },
    '/partners/technology-alliances': {
      name: 'Find a partner',
      sectionUrl: '/partners/technology-alliances',
    },
  },
}
