import React from 'react'

const formatInlineCode = (text: string) => {
  let formattedText: string[]
  if (text && typeof text === "string" && text.includes('<br>')) {
    formattedText = text.split('<br>')
    const brokenText = formattedText.map((item: any, i: number) => {
      let itemText = item
      if (item.includes('_')) {
        const italicArray = itemText.split(/(_)/g)
        itemText = italicArray.map((innerText: string, n: number) => {
          if (innerText === '_' && italicArray[n + 2] === '_') {
            let italicItem = (
              <span className="InlineItalic" key={`InlineItalic-${n}`}>
                {italicArray[n + 1]}
              </span>
            )
            italicArray.splice(n, 2)
            return italicItem
          } else {
            return innerText
          }
        })
      }
      return (
        <span key={`LineBreak-${i}`} className="LineBreak">
          {itemText}
        </span>
      )
    })
    return brokenText
  } else {
    return text
  }
}

export const addClassToLastElementOfReadOnlyJSX = (formattedText: JSX.Element[], classToBeAdded: string) => {
  if (Array.isArray(formattedText)) {
    const lastChild = formattedText.pop()
    const lastChildClassName = lastChild?.props.className
    const lastChildFormatted = Object.assign({}, lastChild, {
      props: {
        ...lastChild?.props,
        className: `${lastChildClassName} ${classToBeAdded}`,
      },
    })
    formattedText.push(lastChildFormatted)
  }
  return formattedText
}

export const addClassToFirstElementOfReadOnlyJSX = (
  formattedText: JSX.Element[],
  classToBeAdded: string
) => {
  if (Array.isArray(formattedText)) {
    const firstChild = formattedText[0]
    const firstChildClassName = firstChild?.props.className
    const firstChildFormatted = Object.assign({}, firstChild, {
      props: {
        ...firstChild?.props,
        className: `${firstChildClassName} ${classToBeAdded}`,
      },
    })
    formattedText[0] = firstChildFormatted
  }
  return formattedText
}





export default formatInlineCode
