import styled from 'styled-components'

export const PageOuter = styled.div`
  padding-top: 64px;
`

export const BodyContent = styled.div`
  padding-top: 36px;
  padding-bottom: 32px;

  ${({ isRobotRelease }) => {
    if (isRobotRelease) {
      return `
    position: relative;  
  `
    }
  }}

  ${({ isWebinar }) => {
    return `
  > :not(a) {
    color: ${isWebinar ? '#131d40' : '#365c86'};
  }
  .richText-styled-list-items {
    > :not(a) {
      color: ${isWebinar ? '#131d40' : '#365c86'};
    }
  }  
  .AR-WP__FinePrint {
    * {
      &:not(a) {
        color: ${isWebinar ? '#131d40' : '#365c86'};
      }
    }
  }
  `
  }}


  .MuiContainer-root {
    padding: 0;
  }

  p {
    margin-bottom: 24px;
  }

  .AR-WP__FinePrint {
    * {
      margin: 0;
    }

    p {
      font-size: 16px;
      margin: 4px 0;
    }

    ol {
      padding-left: 10px;
      counter-reset: item;
      list-style: none;

      li {
        > p {
          font-size: 14px;
        }
        position: relative;
        &:before {
          content: counter(item);
          counter-increment: item;
          position: absolute;
          vertical-align: super;
          font-size: 10px;
          left: -10px;
        }
      }
    }
  }

  div[data-cy='Image'] {
    position: relative;
    bottom: 0;
    z-index: 1;
    margin-bottom: 48px;
    img {
      width: 100%;
    }
    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      background-image: linear-gradient(to bottom, transparent 50%, white 50%),
        linear-gradient(to right, transparent 50%, #9ad4f3 50%);
      background-size: 4px 4px, 4px 4px;
      left: -30px;
      bottom: -30px;
      width: 226px;
      height: 107px;

      @media screen and (min-width: 480px) {
        width: 357px;
        height: 222px;
      }

      @media screen and (min-width: 768px) {
        width: 226px;
        height: 107px;
        bottom: -16px;
      }

      @media screen and (min-width: 1440px) {
        width: 392px;
        height: 186px;
        bottom: -30px;
      }
    }
  }

  blockquote.quote-container.quote-container {
    background-color: #d6f5ff;
    position: relative;
    margin: 80px 0 60px 0;
    border-left: 0;
    padding: 80px 16px 16px;

    ${(p) => p.theme.breakpoints.down('xs')} {
      padding: 48px 24px;
    }

    > p {
      color: #798ea4;
      font-size: 18px;
      line-height: 1.65;
      margin-top: 0;

      @media screen and (min-width: 768px) {
        font-size: 20px;
      }

      @media screen and (min-width: 1024px) {
        font-size: 25px;
      }

      @media screen and (min-width: 1440px) {
        font-size: 28px;
      }
    }
  }

  blockquote::before {
    content: '';
    position: absolute;
    top: -30px;
    left: 18px;
    width: 74px;
    height: 56px;
    background-image: url('https://images.ctfassets.net/5965pury2lcm/6D8NmS3Zf7JZLkVIzD0UjL/97aa5dce32ab883531b4697ff3cf531e/heist-blog-blockquote-quotes.svg');
    ${(p) => p.theme.breakpoints.down('xs')} {
      left: 24px;
    }
  }

  blockquote > p {
    color: #273139;
  }

  blockquote p:last-child,
  blockquote p:last-child span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 32px;
    margin-bottom: 0;
  }

  blockquote p:first-child {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    letter-spacing: -0.5px;
    ${(p) => p.theme.breakpoints.down('md')} {
      font-size: 20px;
      line-height: 32px;
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0px;
    }
  }
`

export const MarketoFormContainer = styled.div`
  ${(p) =>
    p.formAlignToBody
      ? `> div {
          padding: 32px 0;
          padding-right: 0;
          .FormIntro {
            color: #131d40;
            font-weight: 700;
          }
        }`
      : `> div {
            position: sticky;
            top: -130px;
            margin-top: -356px;
            padding: 32px;

            .FormIntro {
              color: #000;
              font-weight: 600;
            }
            ${p.theme.breakpoints.up('sm')} {
              box-shadow: 0px 16px 16px -8px rgba(0, 0, 0, 0.1);
            }
          };
          //href target for hero cta to scroll to form
          #AR-WP__Form {
            position: relative;
            top: -65px;
            display: block;
          }`};

  ${(p) => p.theme.breakpoints.up('sm')} {
    padding-bottom: 32px;
  }

  > div {
    .FormIntro {
      padding-left: 12px;
      margin-bottom: 32px;
      font-size: 18px;
      line-height: 30px;
    }
    width: 100%;
    background: ${(p) => p.theme.webMktPalette.background.white};
    z-index: 1;
    box-sizing: border-box;
    border-radius: 4px;

    ${(p) => p.theme.breakpoints.down('xs')} {
      margin-top: 0;
      position: static;
      padding-top: 24px;
      padding-left: 0;
      padding-right: 0;

      .FormIntro {
        padding-left: 0;
      }
    }

    // form styles
    form {
      #LblmktoCheckbox_117126_0 {
        color: #4e5758;
        box-sizing: border-box;
        padding-right: 5px;
      }

      .mktoFormRow .mktoFormCol {
        min-height: auto;
      }
    }
  }
`

export const BodyOuter = styled.div`
  > div {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0 16px 72px;
    margin: 0 auto;

    ${(p) => p.theme.breakpoints.down('xs')} {
      display: block;
      padding-bottom: 0px;
    }
  }

  ${(p) => {
    let alignment = ``
    if (p.formAlignToBody) {
      alignment = `
        ${p.theme.breakpoints.up('sm')} {
          background-image: linear-gradient(to right, #f3f5fa 50%, #fff 50%);
        }
        > div {
          max-width: 940px;
          grid-column-gap: 72px;
        }
      `
    } else {
      alignment = `
        ${p.theme.breakpoints.up('sm')} {
          background: #f9fcfd;
        }
        > div {
          max-width: 1200px;
          grid-column-gap: 128px;

          @media screen and (max-width: 1440px) {
            grid-column-gap: 32px;
          }
        }
       `
    }

    alignment += `
      ${p.theme.breakpoints.down('xs')} {
        > div {
          padding-left: 0;
          padding-right: 0;
        }
        .AR-WP__Body {
          background: #f3f5fa;
        }
        .AR-WP__Body, ${MarketoFormContainer} {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    `
    return alignment
  }}
`

export const InlineAuthor = styled.div`
  display: flex;
  align-items: center;
  &:last-of-type {
    margin: 0 0 24px;
  }
  .InlineAuthor__Headshot-Img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    width: 90px;
    min-width: 90px;
    margin-right: 16px;
    border-radius: 90px;
    overflow: hidden;
    .InlineAuthor__Headshot-Img-File {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }
  .InlineAuthor__Information {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .InlineAuthor__Name {
      margin: 0 0 4px;
      padding: 0;
      font-size: 22px;
      line-height: 26px;
      font-weight: 400;
    }
    .InlineAuthor__Byline {
      margin: 0;
      padding: 0;
      font-size: 16px;
      line-height: 20px;
      font-weight: 300;
    }
  }

  margin: 0 0 4px;
  padding: 0;
  font-size: 22px;
  font-weight: 400;
`
