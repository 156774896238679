// Libraries
import React from 'react'
import moment from 'moment'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

// Styles
import { ResourceItem, ResourceRowContainer } from './style'

// Components
import Button from '../../global/button'
import GlobalContainer from '../globalContainer'
import Typography from '../typography'

// Helpers
import options from '../../../utils/richText.utils'
import { getMicrocopyData } from '../../../utils/microcopy.utils'
import ImgUtil from '../../../utils/img.utils'
import { isExternalURL, internalUrl, internalLinkHandler } from '../../../utils/link.utils'

// Images
import DefaultGrid from '../../../../static/steam-img/no-image-grid.svg'
import videoButtonPlay from '../../../../static/steam-img/video-button-play.svg'
import formatInlineCode from '../../../utils/shortTextFormatting'

const ResourceRow = (props: any) => {
  if (props.data) {
    let microcopyset: any = [],
      seeAllItemsIntro: string = '',
      seeAllItemsCta: { externalUrl: string; text: string; internalLink?: internalUrl} | undefined,
      featuredPages: any,
      topPadding: string = 'Large',
      bottomPadding: string = 'Large',
      isHomePage: boolean = false

    if (props.isHomePage) isHomePage = props.isHomePage
    if (props.data.microcopyset) microcopyset = props.data.microcopyset
    if (props.data.seeAllItemsIntro)
      seeAllItemsIntro = props.data.seeAllItemsIntro
    if (props.data.seeAllItemsCta) seeAllItemsCta = props.data.seeAllItemsCta
    if (props.data.topPadding) topPadding = props.data.topPadding
    if (props.data.bottomPadding) bottomPadding = props.data.bottomPadding
    if (props.data.featuredPages) featuredPages = props.data.featuredPages
    const getImageUrl = (item: any) =>
      item.image && item.image.file && item.image.file.url
        ? item.image.file.url
        : ''
    const generateCard = (item: any, index: number) => {
      const category =
        !isHomePage && item.category && item.category.title
          ? item.category.title
          : ''
      const image = item.image && getImageUrl(item.image)
      const heading = item.title ? item.title : ''
      const body = item.body ? item.body : ''
      const inEnglish = item.inEnglish && !isHomePage ? item.inEnglish : false
      const url1 = item.url1 ? item.url1 : ''
      let date = item.date ? item.date : ''
      let number =
        item.category && item.category.number ? item.category.number : ''

      let altText = ''
      if (image && item.image.altText) altText = item.image.altText
      if (item.microcopy) {
        microcopyset = item.microcopy
      }

      let categoryIcon = ''
      let companyLogo = ''
      let videoIcon = ''
      let companyAltText = ''
      let categoryAltText = ''

      //category video demo
      if (!isHomePage) {
        if (number == '6') {
          videoIcon = item.category?.colorImage?.image?.file?.url || videoButtonPlay
        } else if (image) {
          companyLogo = item.companyLogo && getImageUrl(item.companyLogo)
          if (!companyLogo && item.category && item.category.whiteImage) {
            categoryIcon = getImageUrl(item.category.whiteImage)
            categoryAltText = item.category.whiteImage.altText
              ? item.category.whiteImage.altText
              : ''
          } else {
            companyAltText = item.companyLogo && item.companyLogo.altText
          }
        } else {
          companyLogo =
            item.companyColorLogo && getImageUrl(item.companyColorLogo)
          if (!companyLogo && item.category && item.category.colorImage) {
            categoryIcon = getImageUrl(item.category.colorImage)
            categoryAltText = item.category.colorImage.altText
              ? item.category.colorImage.altText
              : ''
          } else {
            companyAltText =
              item.companyColorLogo && item.companyColorLogo.altText
          }
        }
      }

      let convertedDate
      if (date) {
        convertedDate = new Date(date)
        date = moment(convertedDate).format('MMMM D, YYYY')
      }

      let cta = getMicrocopyData(microcopyset, number, 'primary', 'text')
      const imageOverlay = image ? true : false

      //7 === Webinar
      //11 === Events
      let ctaText, when
      if (
        number &&
        (number === 7 || number === 11) &&
        cta &&
        cta.length >= 2 &&
        convertedDate
      ) {
        let now = new Date()
        when = convertedDate >= now ? 'before' : 'after'
        if (cta[0].key && cta[0].key.includes(when)) ctaText = cta[0].value
        else if (cta[1].key && cta[1].key.includes(when)) ctaText = cta[1].value
      }

      // If not webinar, get the first cta text value
      if (!ctaText && cta && cta[0] && cta[0].value) ctaText = cta[0].value

      let linkTarget = '_self'
      if (isExternalURL(url1)) {
        linkTarget = '_blank'
      }

      return (
        <ResourceItem
          hasOverlay={imageOverlay}
          className="ResourceRow__Item"
          videoIcon={videoIcon}
          companyLogo={companyLogo}
          key={index}
          categoryIcon={categoryIcon}
        >
          <a href={url1} target={linkTarget}>
            <div className="ResourceRow__Image-Container">
              {companyLogo ? (
                <img
                  className="ResourceRow__Item-Logo"
                  src={ImgUtil.getResizedImageUrl(companyLogo, 'width=240')}
                  alt={companyAltText}
                />
              ) : videoIcon ? (
                <img
                  className="ResourceRow__Item-Icon"
                  src={videoIcon}
                  alt="Play button"
                />
              ) : categoryIcon ? (
                <img
                  className="ResourceRow__Item-Icon"
                  src={ImgUtil.getResizedImageUrl(categoryIcon, '')}
                  alt={categoryAltText}
                />
              ) : (
                ''
              )}

              {inEnglish && (
                <Typography
                  type="microAlt"
                  className="ResourceRow__Pill ResourceRow__Pill--White"
                >
                  IN ENGLISH
                </Typography>
              )}
              {category && category === 'Webinar' && when === 'after' && (
                <Typography
                  type="microAlt"
                  className="ResourceRow__Pill ResourceRow__Pill--Dark"
                >
                  ON DEMAND
                </Typography>
              )}
              <img
                className="ResourceRow__BackgroundImg"
                src={
                  image
                    ? ImgUtil.getResizedImageUrl(image, 'width=400')
                    : DefaultGrid
                }
                alt={altText ? altText : 'Dotted Grid'}
              />
            </div>
            <div className="ResourceRow__Text-Container">
              {!isHomePage && (category || date) && (
                <div className="ResourceRow__Meta-Container">
                  {category && (
                    <Typography
                      type="captionAlt"
                      className="ResourceRow__Item-Category"
                    >
                      {category}
                    </Typography>
                  )}
                  {date && (
                    <Typography
                      type="caption"
                      className="ResourceRow__Item-Date"
                    >
                      {date}
                    </Typography>
                  )}
                </div>
              )}
              {heading && (
                <Typography
                  className="ResourceRow__Item-Heading"
                  type="header6"
                >
                  {formatInlineCode(heading)}
                </Typography>
              )}
              {body && (
                <Typography
                  type="bodyXSWrapper"
                  className="ResourceRow__Item-Body"
                >
                  {renderRichText(body, options)}
                </Typography>
              )}

              {ctaText && (
                <Button
                  icon={true}
                  href={url1}
                  text={ctaText}
                  type="text-link"
                  size="small"
                  theme={'light'}
                />
              )}
            </div>
          </a>
        </ResourceItem>
      )
    }

    return (
      <ResourceRowContainer
        topPadding={topPadding}
        bottomPadding={bottomPadding}
        isHomePage={isHomePage}
        data-cy={`ResourceRow${isHomePage ? '-Homepage' : ''}`}
      >
        <GlobalContainer className="ResourceRow__Outer-Container">
          <div className="ResourceRow__Card-Container">
            {featuredPages &&
              featuredPages.map((element: any, index: number) =>
                generateCard(element, index)
              )}
          </div>
          {seeAllItemsIntro && (
            <Typography type="bodyXS" className="ResourceRow__SeeAllItemsIntro">
              {seeAllItemsIntro}
            </Typography>
          )}
          {seeAllItemsCta && (
            <div className="ResourceRow__CTA-Container">
              <Button
                icon={true}
                href={
                  seeAllItemsCta.internalLink
                    ? internalLinkHandler(seeAllItemsCta.internalLink)
                    : seeAllItemsCta.externalUrl
                }
                text={seeAllItemsCta.text}
                type="tertiary"
                size="small"
                theme={'light'}
              />
            </div>
          )}
        </GlobalContainer>
      </ResourceRowContainer>
    )
  }

  return null
}

export default ResourceRow
