import styled from 'styled-components'

export const ImageContainer = styled.div`
  ${({ $outerPage, theme }) => {
    if ($outerPage && $outerPage === 'aboutRPA') {
      return `
      background: ${theme.webMktPalette.background.gray};
      text-align: center;
      .Image__Element {
        width: 792px;
        height: auto;
        margin: 0 auto;
        ${theme.breakpoints.down('md')} {
          width: 796px;
        }
        ${theme.breakpoints.down('sm')} {
          width: 536px;
        }
        ${theme.breakpoints.down('xs')} {
          width: 328px;
        }
      }
    `
    }
  }}
`
