import styled from 'styled-components'

export const FeaturedResourceContainer = styled.div`
  ${({ resourceTheme, theme }) => {
    let eyebrowColor, background, bodyTextColor, dottedGridColor
    if (resourceTheme === 'dark') {
      background = theme.webMktPalette.background.dark
      eyebrowColor = 'rgb(89, 90, 92)'
      bodyTextColor = theme.webMktPalette.text.primaryWhite
      dottedGridColor = 'rgba(255,255,255,.70)'
    } else if (resourceTheme === 'light') {
      background = theme.webMktPalette.background.white
      eyebrowColor = 'rgb(89, 90, 92)'
      bodyTextColor = theme.webMktPalette.text.primaryBlack
      dottedGridColor = 'rgba(0,0,0,.50)'
    }
    return `
    background: ${background};
    padding: 96px 0 72px 0;
    ${theme.breakpoints.down('sm')} {
      padding: 64px 0 40px 0;
    }
    .FeaturedResource__Eyebrow {
      color: ${eyebrowColor};
      margin-bottom: 12px;
      ${theme.breakpoints.down('sm')} {
        margin-bottom: 8px;
      }
    }
    .FeaturedResource__Inner-Heading {
      color: ${bodyTextColor};
    }
    .FeaturedResource__Image-Graphic-Outer:before {
      background-image: radial-gradient(${dottedGridColor} 1px, transparent 0);
    }
    .FeaturedResource__Inner-Body{
      margin: 24px 0 0 0;
      p, li {
        color: ${bodyTextColor};
      }
      p {
        margin: 24px 0;
      }

      ul {
        list-style-type: disc;
        padding-left: 24px;
      }
      li {
        p {
          margin: 0;
        }
        a {
          margin: 0 0 0 12px;
          display: inline;
        }
        margin-bottom: 8px;
      }
      a {
        color: ${
          resourceTheme === 'dark'
            ? theme.webMktPalette.text.actionDark
            : theme.webMktPalette.text.actionLight
        };
        &:hover {
          text-decoration: underline;
        }
      }
    }
  `
  }}
  .FeaturedResource__Image {
    height: 100%;
    width: auto;
    object-fit: cover;
  }
  .FeaturedResource__Inner-Heading {
    margin-bottom: 24px;
  }
  .FeaturedResource__CTA-1 {
    margin-right: 32px;
    margin-bottom: 24px;
    ${(p) => p.theme.breakpoints.down('sm')} {
      justify-content: space-between;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin-right: 0;
      width: 100%;
    }
  }
  .FeaturedResource__CTA-Container {
    margin-top: 32px;
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .FeaturedResource__Inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    ${(p) => p.theme.breakpoints.down('xs')} {
      flex-direction: column;
      width: 100%;
    }
    .FeaturedResource__Image-Graphic-Outer {
      height: 330px;
      position: relative;
      align-self: flex-start;
      margin-left: 102px;
      ${(p) => p.theme.breakpoints.down('md')} {
        height: 270px;
        margin-left: 82px;
      }
      ${(p) => p.theme.breakpoints.down('sm')} {
        height: 204px;
        margin-left: 48px;
      }
      ${(p) => p.theme.breakpoints.down('xs')} {
        margin-left: 0;
        height: 272px;
        margin-bottom: 48px;
        align-self: center;
      }
      &:before {
        content: '';
        position: absolute;
        width: 378px;
        height: 189px;
        z-index: 0;
        top: 0;
        left: -48px;
        background-size: 24px 24px;
        ${(p) => p.theme.breakpoints.down('md')} {
          height: 159px;
          width: 318px;
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
          height: 118px;
          width: 236px;
          left: -32px;
        }
        ${(p) => p.theme.breakpoints.down('xs')} {
          height: 152px;
          width: 328px;
          left: -44px;
        }
      }
    }
    .FeaturedResource__Image-Container {
      height: 282px;
      width: 282px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 48px;
      position: relative;
      ${(p) => p.theme.breakpoints.down('md')} {
        height: 222px;
        width: 222px;
      }
      ${(p) => p.theme.breakpoints.down('sm')} {
        height: 172px;
        width: 172px;
        margin-top: 32px;
      }
      ${(p) => p.theme.breakpoints.down('xs')} {
        height: 240px;
        width: 240px;
      }
      .FeaturedResource__Lower-Icon {
        z-index: 2;
        width: auto;
        height: 100%;
        object-fit: contain;
      }
    }
    .FeaturedResource__Partner-Logo-Container {
      position: absolute;
      z-index: 1;
      background: rgb(0 103 223 / 20%);
      width: 100%;
      height: 100%;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 81px 21px;
      box-sizing: border-box;
      ${(p) => p.theme.breakpoints.down('md')} {
        padding: 63px 16px;
      }
      ${(p) => p.theme.breakpoints.down('sm')} {
        padding: 47px 8px;
      }
      ${(p) => p.theme.breakpoints.down('xs')} {
        padding: 70px 20px;
      }
      .FeaturedResource__Partner-Logo {
        width: auto;
        object-fit: contain;
      }
    }
    .FeaturedResource__Lower-Icon-Container {
      position: absolute;
      width: 56px;
      height: 56px;
      background: ${(p) => p.theme.webMktPalette.button.primary};
      z-index: 2;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      ${(p) => p.theme.breakpoints.down('sm')} {
        width: 48px;
        height: 48px;
      }
      .FeaturedResource__Lower-Icon {
        width: 40px;
        height: 40px;
      }
    }

    .FeaturedResource__Inner-Text {
      max-width: 691px;
      width: 100%;
      margin-left: auto;
      ${(p) => p.theme.breakpoints.down('md')} {
        max-width: 550px;
      }
      ${(p) => p.theme.breakpoints.down('sm')} {
        max-width: 454px;
      }
      ${(p) => p.theme.breakpoints.down('xs')} {
        max-width: 328px;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
`
