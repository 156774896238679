import React from 'react'

// Styles
import { StyledButton, StyledIconButton } from './button'

// Helpers
import { isExternalURL } from '../../../utils/link.utils'

const Arrow = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="https://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
    >
      <g
        id="24x24/ArrowLong_Right"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M19.6027304,11 L16.3425076,7.70314549 C15.9541711,7.31044563 15.9577083,6.67729053 16.3504081,6.288954 C16.743108,5.90061747 17.3762631,5.90415464 17.7645996,6.29685451 L22.6606057,11.2478719 C22.7431528,11.321536 22.8123701,11.4082939 22.8663149,11.5034921 C22.9054543,11.571839 22.9358621,11.6438045 22.9575473,11.717772 C23.0507609,12.0306735 22.9956549,12.3892645 22.7405358,12.6698468 C22.73003,12.6814717 22.7192031,12.6929085 22.708055,12.7041457 L17.7645996,17.7031455 C17.3762631,18.0958454 16.743108,18.0993825 16.3504081,17.711046 C15.9577083,17.3227095 15.9541711,16.6895544 16.3425076,16.2968545 L19.6027304,13 L1.99799805,12.9999999 C1.4457133,13 0.997998051,12.5522847 0.997998051,11.9999999 C0.997998051,11.4477153 1.4457133,11 1.99799805,10.9999999 L19.6027304,11 Z"
          id="ArrowPath"
          fill="#000000"
        ></path>
      </g>
    </svg>
  )
}

type ButtonProps = {
  icon?: boolean
  target?: '_blank' | '_self'
  alignment?: 'left' | 'center'
  rel?: string
  className?: string
  ariaLabel?: string
  clickHandler?: any
  href?: string
  text?: string
  size?: 'small' | 'medium' | 'large'
  arrowOrientation?: 'left' | 'right'
  type: 'primary' | 'secondary' | 'tertiary' | 'text-link' | 'icon'
  theme: 'light' | 'dark'
  useCase?: string
}

const Button = (props: ButtonProps) => {
  let target = props.target ? props.target : undefined
  if (props.href) {
    if (isExternalURL(props.href)) {
      target = '_blank'
    } else {
      target = '_self'
    }
  }

  if (props.type !== 'icon') {
    return (
      <StyledButton
        data-cy="Button"
        className={`CTA ${props.className ? props.className : ''}`}
        href={props.href ? props.href : '/'}
        rel={props.rel ? props.rel : 'noopener'}
        target={target}
        $size={props.size}
        $type={props.type}
        $alignment={props.alignment}
        $icon={props.icon}
        $buttonTheme={props.theme}
        $useCase={props.useCase}
      >
        <span>{props.text}</span>
        {props.icon && (
          <div className="CTA__Arrow-Container">
            <Arrow />
          </div>
        )}
      </StyledButton>
    )
  } else {
    return (
      <StyledIconButton
        data-cy="Button"
        color="primary"
        aria-label={props.ariaLabel ? props.ariaLabel : 'icon button'}
        className={props.className ? props.className : ''}
        onClick={props.clickHandler}
        disableRipple={true}
        $buttonTheme={props.theme}
        $arrowOrientation={
          props.arrowOrientation ? props.arrowOrientation : 'left'
        }
      >
        <div className="Quote__Slider-Button-Circle">
          <Arrow />
        </div>
      </StyledIconButton>
    )
  }
}

export default Button
