import React from 'react'
import * as styles from './style'
import GlobalContainer from '../globalContainer'
import DottedModal from '../../global/dottedModal'
import Button from '../../global/button'
import VideoPlayer from '../videoPlayer'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Typography from '../typography'

// Helpers
import options from '../../../utils/richText.utils'
import formatInlineCode from '../../../utils/shortTextFormatting'
import { internalUrl, internalLinkHandler } from '../../../utils/link.utils'
import ImgUtil from '../../../utils/img.utils'

const MediaText = (props: any) => {
  if (props.data) {
    const [open, setOpen] = React.useState(false)

    const handleClose = () => {
      setOpen(false)
    }
    const handleOpen = () => {
      setOpen(true)
    }

    const type = (mediaStyle: string) => {
      if (mediaStyle === 'Video') return 'VIDEO'
      else if (mediaStyle === 'Photo') return '16X9'
      else return 'NONE'
    }

    let headline: string = '',
      body: any,
      cta:
        | { externalUrl: string; text: string; internalLink?: internalUrl }
        | undefined,
      imageAlignment: string = 'left',
      asset: string = '',
      videoId: string = '',
      assetAltText: string = '',
      theme: string = 'Light',
      topPadding: string = 'None',
      bottomPadding: string = 'Large',
      animation: boolean = false,
      mediaStyle: string = 'Photo',
      animationMP4: boolean = false,
      assetTypename: string = '',
      start: string = ''

    if (props.data.assetTextTheme) theme = props.data.assetTextTheme
    if (props.data.imageAlignment) imageAlignment = props.data.imageAlignment
    if (props.data.mediaStyle) mediaStyle = props.data.mediaStyle
    if (props.data.asset && props.data.asset.__typename) {
      assetTypename = props.data.asset.__typename
    }

    if (props.data.asset) {
      if (
        props.data.asset &&
        props.data.asset.image &&
        props.data.asset.image.file &&
        props.data.asset.image.file.url
      ) {
        asset = props.data.asset.image.file.url
      }
      if (props.data.asset && props.data.asset.altText) {
        assetAltText = props.data.asset.altText
      }
      if (assetTypename && assetTypename === 'ContentfulWrapperVideo') {
        if (props.data.asset.youtubeId) videoId = props.data.asset.youtubeId
        else if (props.data.asset.vimeoId) videoId = props.data.asset.vimeoId
        if (
          props.data.asset.animationFile &&
          props.data.asset.animationFile.file &&
          props.data.asset.animationFile.file.url &&
          mediaStyle === 'Animation'
        ) {
          asset = props.data.asset.animationFile.file.url
          let last = asset.lastIndexOf('.')
          if (asset.substring(last).includes('.mp4')) animationMP4 = true
          animation = true
        }
        if (props.data.asset.start) start = props.data.asset.start
      }
    }

    if (
      (mediaStyle === 'Video' && !videoId) ||
      (mediaStyle === 'Animation' && !animation) ||
      (assetTypename &&
        assetTypename === 'ContentfulWrapperVideo' &&
        mediaStyle != 'Video' &&
        mediaStyle != 'Animation')
    ) {
      return null
    }

    if (props.data.topPadding) topPadding = props.data.topPadding
    if (props.data.bottomPadding) bottomPadding = props.data.bottomPadding
    if (props.data.headline) headline = props.data.headline
    if (props.data.body) body = props.data.body
    if (props.data.assetTextCta) cta = props.data.assetTextCta

    const mask = type(mediaStyle)

    return (
      <styles.MediaTextContainer
        background={theme}
        alignment={imageAlignment}
        enableClickToEnlarge={mediaStyle === 'Enlargable'}
        topPadding={topPadding}
        bottomPadding={bottomPadding}
        mediaStyle={mediaStyle}
        data-cy="MediaText"
      >
        <GlobalContainer
          className="MediaText__Outer-Container"
          maxWidth={false}
        >
          <div className="MediaText__Inner-Container">
            {mediaStyle === 'Enlargable' && !videoId && !animation && (
              <DottedModal open={open} handleClose={handleClose} type="image">
                <img
                  src={ImgUtil.getResizedImageUrl(asset, '')}
                  srcSet={ImgUtil.getSrcSet(asset)}
                  sizes={'50vw'}
                  loading="lazy"
                  alt={assetAltText}
                />
              </DottedModal>
            )}
            <div className="MediaText__Image-Container" onClick={handleOpen}>
              <div className="MediaText__Mask-Wrapper">
                {mask === 'NONE' && (
                  <div className="MediaText__Mask--None">
                    {animation && animationMP4 ? (
                      <video src={asset} playsInline autoPlay muted loop />
                    ) : (
                      <img
                        alt={assetAltText}
                        src={ImgUtil.getResizedImageUrl(asset, '')}
                        loading="lazy"
                      />
                    )}
                  </div>
                )}
                {mask === 'VIDEO' && (
                  <div className="MediaText__Mask--Video">
                    <VideoPlayer
                      width={'100%'}
                      height="429"
                      videoId={videoId}
                      img={asset}
                      type={
                        videoId && props.data.asset.youtubeId
                          ? 'youtube'
                          : 'vimeo'
                      }
                      backgroundAltText={assetAltText}
                      backgroundImgParams={'width=690'}
                      start={start}
                    />
                  </div>
                )}
                {mask === '16X9' && (
                  <div className="MediaText__Mask--Image">
                    <img
                      src={ImgUtil.getResizedImageUrl(asset, '')}
                      loading="lazy"
                      alt={assetAltText}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="MediaText__Text-Container">
              {headline && (
                <Typography type="header3" className="MediaText__Heading">
                  {formatInlineCode(headline)}
                </Typography>
              )}
              {body && (
                <Typography type="body" className="MediaText__Body">
                  {renderRichText(body, options)}
                </Typography>
              )}
              {cta && (
                <Button
                  icon={true}
                  href={
                    cta.internalLink
                      ? internalLinkHandler(cta.internalLink)
                      : cta.externalUrl
                  }
                  text={cta.text}
                  type="text-link"
                  size="small"
                  theme={theme === 'Dark' ? 'dark' : 'light'}
                />
              )}
            </div>
          </div>
        </GlobalContainer>
      </styles.MediaTextContainer>
    )
  }

  return null
}

export default MediaText
