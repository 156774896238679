import styled from 'styled-components'

export const IconsContainer = styled.div`
  ${(p) => {
    let paddingStyles = ''
    if (p.topPadding === 'Large') paddingStyles += 'padding-top: 96px;'
    else if (p.topPadding === 'Small') paddingStyles += 'padding-top: 64px;'
    if (p.bottomPadding === 'Large') paddingStyles += 'padding-bottom: 96px;'
    else if (p.bottomPadding === 'Small')
      paddingStyles += 'padding-bottom: 64px;'

    let alignmentStyles = ''
    if (p.layout === 'Text to Right') {
      alignmentStyles += `
        .Icons__Item-Container {
          display: flex;
        }
        .Icons__Text-Container {
          padding-left: 24px;
        }
        h6 {
          margin-bottom: 16px;
        }
      `
    } else {
      if (p.layout === 'Text Below Centre-aligned')
        alignmentStyles += `text-align: center;`
      alignmentStyles += `
        h6 {
          margin: 16px 0;
        }
      `
    }
    let themeStyles = ''
    if (p.background === 'Dark') {
      themeStyles += `
        background: ${p.theme.webMktPalette.background.dark};
        a {
          color: #38c6f4;
        }
        p, h6, li {
          color: rgb(255, 255, 255);
        }
      `
    } else {
      themeStyles += `
        ${
          p.background === 'Grey'
            ? `background: ${p.theme.webMktPalette.background.gray};`
            : ''
        }
        p, h6, li {
          color: rgb(0, 0, 0);
        }
      `
    }

    let columnsStyles = `
      .Icons__Outer-Container {
        ${
          p.columns === '5'
            ? `${p.theme.breakpoints.up('lg')} {
                  max-width: 996px;
                }
              `
            : ''
        }
        margin: 0 auto;
        margin-top: -48px;

        ${p.theme.breakpoints.down('sm')} {
          margin-top: -32px;
        }
      }
      ${p.theme.breakpoints.down('sm')} {
        .Icons__Inner-Container {
          width: calc(100% + 16px);
          margin: 0 -8px;
        }

        .Icons__Item-Container {
          padding: 0 8px;
        }
      }
    `

    if (p.columns === '3') {
      columnsStyles += `
        ${p.theme.breakpoints.up('lg')} {
          .Icons__Inner-Container {
            width: calc(100% + 38px);
            margin: 0 -19px;
          }

          .Icons__Item-Container {
            padding: 0 19px;
          }
        }
      `
    }

    if (p.columns === '5') {
      columnsStyles += `
        ${p.theme.breakpoints.up('sm')} {
          .Icons__Item-Container {
            flex-grow: 0;
            max-width: 20%;
            flex-basis: 20%;
          }
        }
      `
    }

    if (p.columns !== '5' && p.columns !== '6') {
      columnsStyles += `
        .Icons__Outer-Container {
          ${p.theme.breakpoints.down('xs')} {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      `
    }

    return `
      overflow: hidden;
      ${paddingStyles}
      ${alignmentStyles}
      ${themeStyles}
      ${columnsStyles}

      .Icons__Outer-Container {
        ${p.theme.breakpoints.down('xs')} {
          max-width: 328px;
        }
      }
      .Icons__Item-Container {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 48px;
        ${p.theme.breakpoints.down('sm')} {
          margin-top: 32px;
        }
        img {
          height: 64px;
          width: 64px;
          object-fit: contain;
        }
      }

      .Icons__Text-Container {
        .Icons__Title {
          margin: 0 0 16px;
        }

        p {
          margin: 0;
        }

        ul {
          list-style: disc;
          padding-left: 24px;
          margin-top: 16px;
        }

        li {
          margin: 0 0 8px;
        }
      }

      .Icon__CTA {
        margin-top: 16px;
      }
    `
  }}
`
