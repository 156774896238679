import styled from 'styled-components'

export const ProductPageVideoContainer = styled.div`
  ${(p) => {
    let paddingTop = ``
    if (p.bottomPadding === 'Large') paddingTop = `padding-bottom: 96px;`
    if (p.bottomPadding === 'Small') paddingTop = `padding-bottom: 64px;`

    const dotGridLgHeight = '263px'
    const dotGridMdHeight = '220px'
    const dotGridSmHeight = '197px'
    const dotGridXsHeight = '104px'

    return `
      width: 100%;
      text-align: center;
      background-color: #fff;
      position: relative;
      overflow: hidden;
      ${paddingTop}

      &:before {
        content: '';
        background-size: 22px 22px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: ${dotGridLgHeight};
        width: 1200px;
        z-index: 1;
        ${p.theme.breakpoints.down('md')} {
          height: ${dotGridMdHeight};
          width: 960px;
        }
        ${p.theme.breakpoints.down('sm')} {
          height: ${dotGridSmHeight};
          width: 736px;
        }
        ${p.theme.breakpoints.down('xs')} {
          height: ${dotGridXsHeight};
          background-image: none;
        }
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: calc(100% - ${dotGridLgHeight});
        ${p.theme.breakpoints.down('md')} {
          height: calc(100% - ${dotGridMdHeight});
        }
        ${p.theme.breakpoints.down('sm')} {
          height: calc(100% - ${dotGridSmHeight});
        }
        ${p.theme.breakpoints.down('xs')} {
          height: calc(100% - ${dotGridXsHeight});
        }
      }
      .VideoSpotlight__Body {
        position: relative;
        z-index: 1;
        margin-top: 32px;
        p {
          font-size: 20px;
          font-weight: normal;
          letter-spacing: -0.5px;
          line-height: 32px;
          ${p.theme.breakpoints.down('xs')} {
            font-size: 18px;
            letter-spacing: 0px;
            line-height: 24px;
          }
        }
      }

      .CTA {
        margin-top: 32px;
        z-index: 1;
      }`
  }}
`

export const VidWrapper = styled.div`
  margin: 0 auto;
  padding-top: 48px;
  max-width: 792px;

  img {
    width: 100%;
    height: 429px;
    object-fit: cover;
    object-position: center;

    ${(p) => p.theme.breakpoints.down('md')} {
      height: 343px !important;
    }

    ${(p) => p.theme.breakpoints.down('sm')} {
      height: 297px !important;
    }

    ${(p) => p.theme.breakpoints.down('xs')} {
      height: 191px !important;
    }
  }
  ${(p) => p.theme.breakpoints.down('md')} {
    max-width: 632px;
  }
  ${(p) => p.theme.breakpoints.down('sm')} {
    max-width: 548px;
  }
  ${(p) => p.theme.breakpoints.down('xs')} {
    padding: 0 16px;
  }
`

export const VideoSpotlightContainer = styled.div`
  ${(p) => {
    if (p.topPadding === 'Large') return `padding-top: 96px;`
    if (p.topPadding === 'Small') return `padding-top: 64px;`
    if (p.topPadding === 'None') return `padding-top: 0;`
  }}

  .VideoSpotlight__Logo-Container {
    max-width: 575px;
    width: 100%;
    margin: 0 auto 24px auto;
    img {
      width: 100%;
      height: auto;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      padding: 0 21px;
      box-sizing: border-box;
    }
  }

  .VideoSpotlight__Content-Container {
    max-width: 792px;
    margin: 0 auto;
    ${(p) => p.theme.breakpoints.down('sm')} {
      max-width: 736px;
    }

    ${(p) => p.theme.breakpoints.down('xs')} {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .VideoSpotlight__Headline {
    text-align: center;
    margin-bottom: 24px;
    color: rgb(0, 0, 0);
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    ${(p) => p.theme.breakpoints.down('xs')} {
      font-size: 28px;
      letter-spacing: 0px;
      line-height: 36px;
    }
  }
  .VideoSpotlight__Subheadline {
    color: ${(p) => p.theme.webMktPalette.text.primaryBlack};
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    letter-spacing: -0.5px;
    line-height: 36px;
    margin-bottom: 64px;
    ${(p) => p.theme.breakpoints.down('sm')} {
      margin-bottom: 48px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      font-size: 20px;
      letter-spacing: 0px;
      line-height: 32px;
    }
  }

  ${(props) => {
    let themeStyles = ``
    if (props.background === 'Dark') {
      themeStyles += `
        ${ProductPageVideoContainer}:before {
          background-color: white;
        }
        ${ProductPageVideoContainer}:after {
          background-color: ${props.theme.webMktPalette.background.dark};
        }
        .VideoSpotlight__Body {
          p {
            color: ${props.theme.webMktPalette.background.white};
          }
        }
      `
    } else {
      //Light or Grey
      if (props.background === 'Light') {
        themeStyles += `
          background-color: ${props.theme.webMktPalette.background.gray};
          ${ProductPageVideoContainer} {
            background-color: ${props.theme.webMktPalette.background.gray};
            &:before {
              background-color: ${props.theme.webMktPalette.background.gray};
            }
            &:after {
              background-color: ${
                props.useCase && props.useCase === 'Resource'
                  ? props.theme.webMktPalette.background.gray
                  : props.theme.webMktPalette.background.white
              };
            }
          }
        `
      } else {
        themeStyles += `
          ${ProductPageVideoContainer}:before {
            background-color: white;
          }
          ${ProductPageVideoContainer}:after {
            background-color: ${props.theme.webMktPalette.background.gray};
          }
        `
      }
      themeStyles += `
        .VideoSpotlight__Body {
          p {
            color: ${props.theme.webMktPalette.text.primaryBlack};
          }
        }
      `
    }
    return `
      ${themeStyles}
      ${ProductPageVideoContainer} {
        &:before {
          background-image: radial-gradient(${
            props.useCase && props.useCase === 'Resource'
              ? '#000000'
              : `#cecece`
          } 1px, transparent 0);
        }
      }
    `
  }};
`
