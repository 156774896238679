import React from 'react'
import Container from '@material-ui/core/Container'
import ImgUtil from '../../../utils/img.utils'
import { ImageContainer } from './image'

const Image = (props: any) => {
  if (
    props.data.imageFile &&
    props.data.imageFile.file &&
    props.data.imageFile.file.url
  ) {
    let alt = props.data.imageFile.title ? props.data.imageFile.title : ''
    let alignmentClass = props.data.imageSelfAlignment
      ? `Align--${props.data.imageSelfAlignment}`
      : ''
    const outerPage = props.outerPage ? props.outerPage : 'generic'

    return (
      <ImageContainer $outerPage={outerPage}>
        <Container className={alignmentClass} data-cy="Image">
          <img
            loading="lazy"
            alt={alt}
            className="Image__Element"
            src={ImgUtil.getResizedImageUrl(props.data.imageFile.file.url, '')}
          />
        </Container>
      </ImageContainer>
    )
  }

  return null
}

export default Image
