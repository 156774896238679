import styled from 'styled-components'

// Components
import IconButton from '@material-ui/core/IconButton'

export const StyledButton = styled.a`
  && {
    ${({ $size, $type, theme, $alignment, $icon, $buttonTheme, $useCase }) => {
      let typeStyles, sizeStyles, alignmentStyles
      // Size styles
      if ($size === 'large') {
        sizeStyles = `
        height: 64px;
      `
      }
      if ($size === 'medium') {
        sizeStyles = `
        height: 56px;
      `
      }
      if ($size === 'small') {
        sizeStyles = `
        height: 48px;
      `
      }

      // Variant styles
      if ($type === 'primary') {
        if ($buttonTheme === 'light') {
          typeStyles = `
            background: ${theme.webMktPalette.button.primary};
            color: ${theme.webMktPalette.text.primaryWhite};
            &:hover {
              background: #000000;
              color: ${theme.webMktPalette.text.primaryWhite};
              .CTA__Arrow-Container {
                svg {
                  #ArrowPath {
                    fill: ${theme.webMktPalette.icon.white};
                  }
                }
              }
            }
            &:active {
              background: ${theme.webMktPalette.background.dark};
              .CTA__Arrow-Container {
                svg {
                  #ArrowPath {
                    fill: ${theme.webMktPalette.icon.white};
                  }
                }
              }
            }
            .CTA__Arrow-Container {
              svg {
                #ArrowPath {
                  fill: ${theme.webMktPalette.icon.white};
                }
              }
            }
          `
        }
        if ($buttonTheme === 'dark') {
          typeStyles = `
            background: ${theme.webMktPalette.button.primary};
            color: ${theme.webMktPalette.text.primaryWhite};
            &:hover {
              background: ${theme.webMktPalette.background.white};
              color: ${theme.webMktPalette.text.primaryBlack};
              .CTA__Arrow-Container {
                svg {
                  #ArrowPath {
                    fill: ${theme.webMktPalette.icon.black};
                  }
                }
              }
            }
            &:active {
              background: rgba(255,255,255,.8);
              color: ${theme.webMktPalette.text.secondary};
              .CTA__Arrow-Container {
                svg {
                  #ArrowPath {
                    fill: #595a5c;
                  }
                }
              }
            }
            .CTA__Arrow-Container {
              svg {
                #ArrowPath {
                  fill: ${theme.webMktPalette.icon.white};
                }
              }
            }
          `
        }
      }
      if ($type === 'secondary') {
        if ($buttonTheme === 'light') {
          typeStyles = `
            background: ${theme.webMktPalette.button.secondaryBlack};
            color: ${theme.webMktPalette.text.primaryWhite};
            &:hover {
              background: ${
                $useCase === 'Pricing'
                  ? theme.webMktPalette.grayNeutral[15]
                  : theme.webMktPalette.background.white
              };
              color: ${theme.webMktPalette.text.primaryBlack};
              .CTA__Arrow-Container {
                svg {
                  #ArrowPath {
                    fill: ${theme.webMktPalette.icon.black};
                  }
                }
              }
            }
            &:active {
              color: ${theme.webMktPalette.text.secondary};
              .CTA__Arrow-Container {
                svg {
                  #ArrowPath {
                    fill: #595A5C;
                  }
                }
              }
            }
            .CTA__Arrow-Container {
              svg {
                #ArrowPath {
                  fill: ${theme.webMktPalette.icon.white};
                }
              }
            }
          `
        }
        if ($buttonTheme === 'dark') {
          typeStyles = `
            background: ${theme.webMktPalette.button.secondaryWhite};
            color: ${theme.webMktPalette.text.primaryBlack};
            &:hover {
              background: #000000;
              color: ${theme.webMktPalette.text.primaryWhite};
              .CTA__Arrow-Container {
                svg {
                  #ArrowPath {
                    fill: ${theme.webMktPalette.icon.white};
                  }
                }
              }
            }
            &:active {
              color: ${theme.webMktPalette.text.secondary};
              .CTA__Arrow-Container {
                svg {
                  #ArrowPath {
                    fill: #595A5C;
                  }
                }
              }
            }
          `
        }
      }
      if ($type === 'tertiary') {
        if ($buttonTheme === 'light') {
          typeStyles = `
            background: none;
            color: ${theme.webMktPalette.text.primaryBlack};
            border: 2px solid #000000;
            .CTA__Arrow-Container {
              svg {
                #ArrowPath {
                  fill: ${theme.webMktPalette.icon.black};
                }
              }
            }
            &:hover {
              background: rgb(0 0 0 / .2);
              color: ${theme.webMktPalette.text.primaryBlack};
              border: 2px solid #000000;
              .CTA__Arrow-Container {
                svg {
                  #ArrowPath {
                    fill: ${theme.webMktPalette.icon.black};
                  }
                }
              }
            }
            &:active {
              background: rgb(0 0 0 / .2);
              color: #9D9D9D;
              border: 2px solid #000000;
              .CTA__Arrow-Container {
                svg {
                  #ArrowPath {
                    fill: #9D9D9D;
                  }
                }
              }
            }

          `
        }
        if ($buttonTheme === 'dark') {
          typeStyles = `
            background: none;
            color: ${theme.webMktPalette.text.primaryWhite};
            border: 2px solid #ffffff;
            .CTA__Arrow-Container {
              svg {
                #ArrowPath {
                  fill: ${theme.webMktPalette.icon.white};
                }
              }
            }
            &:hover {
              background: rgb(255 255 255 / .2);
              color: ${theme.webMktPalette.text.primaryWhite};
              border: 2px solid #ffffff;
              .CTA__Arrow-Container {
                svg {
                  #ArrowPath {
                    fill: ${theme.webMktPalette.icon.white};
                  }
                }
              }
            }
            &:active {
              background: rgb(255 255 255 / .2);
              color: #666767;
              border: 2px solid #ffffff;
              .CTA__Arrow-Container {
                svg {
                  #ArrowPath {
                    fill: #666767;
                  }
                }
              }
            }
          `
        }
      }

      // Alignment styles
      if ($alignment === 'center') {
        alignmentStyles = `
        text-align: center;
        padding-left: 32px;
        padding-right: 32px;
      `
      }
      if ($alignment === 'left' && !$icon) {
        alignmentStyles = `
        text-align: left;
        padding-right: 64px;
      `
      }

      if ($icon) {
        alignmentStyles = `
          text-align: left;
          padding-right: 24px;
          .CTA__$Icon {
            margin-left: 16px;
          }
        `
      }

      // Button or Text Link styles
      const buttonStyles = `
        transition: 0.1s all cubic-bezier(0.4, 0.0, 0.2, 1);
        border-radius: 0;
        padding: 12px 12px 12px 24px;
        ${typeStyles}
        ${sizeStyles}
        ${alignmentStyles}
        .CTA__$Icon {
          width: 24px;
          height: 24px;
        }
        &:hover {
          text-decoration: none;
        }
        display: inline-flex;
        flex-direction: row;
        align-items: center;
      `
      const textLinkStyles = `
        color: ${
          $buttonTheme === 'dark'
            ? theme.webMktPalette.icon.blueDark
            : theme.webMktPalette.icon.blueLight
        };
        text-decoration: none;
        position: relative;
        display: inline-block;
        position: relative;
        padding-right: 40px;

        .CTA__Arrow-Container {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }

        &:hover {
          color: ${
            $buttonTheme === 'dark'
              ? theme.webMktPalette.icon.blueDark
              : theme.webMktPalette.icon.blueLight
          };
          span {
            border-bottom: 2px solid;
            width: calc(100% - 40px);
          }
          .CTA__Arrow-Container {
            svg {
              #ArrowPath {
                fill: ${
                  $buttonTheme === 'dark'
                    ? theme.webMktPalette.icon.blueDark
                    : theme.webMktPalette.icon.blueLight
                };
              }
            }
          }
        }
        &:focus {
          color: ${
            $buttonTheme === 'dark'
              ? theme.webMktPalette.icon.blueDark
              : `rgb(0 103 223 / .5)`
          };
          .CTA__Arrow-Container {
            svg {
              #ArrowPath {
                fill: ${
                  $buttonTheme === 'dark'
                    ? theme.webMktPalette.icon.blueDark
                    : `rgb(0 103 223 / .5)`
                };
              }
            }
          }
        }
        .CTA__Arrow-Container {
          svg {
            #ArrowPath {
              fill: ${
                $buttonTheme === 'dark'
                  ? theme.webMktPalette.icon.blueDark
                  : theme.webMktPalette.icon.blueLight
              };
            }
          }
        }
      `

      return `
        ${$type === 'text-link' ? textLinkStyles : buttonStyles}
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 24px;
        box-sizing: border-box;
        .CTA__Arrow-Container {
          height: 24px;
          width: 24px;
          margin-left: 16px;
        }
      `
    }}
  }
`

export const StyledIconButton = styled(IconButton)`
  && {
    ${({ theme, $buttonTheme, $arrowOrientation }) => {
      let arrowStyles, buttonStyles
      if ($arrowOrientation === 'left') {
        arrowStyles = `
        transform: rotate(180deg);
      `
      } else {
        arrowStyles = undefined
      }
      if ($buttonTheme === 'light') {
        buttonStyles = `
          &:hover {
            background: rgb(0 0 0 / .2);
          }
          &:active {
            background: rgb(0 0 0 / .2);
            #ArrowPath {
              fill: #9D9D9D;
            }
          }
        `
      }
      if ($buttonTheme === 'dark') {
        buttonStyles = `
          &:hover { 
            background: rgb(255 255 255 / .2);
          }
          &:active {
            background: rgb(255 255 255 / .2);
            #ArrowPath {
              fill: #666767;
            }
          }
        `
      }
      return `
      ${buttonStyles}
      #ArrowPath {
        fill: ${$buttonTheme === 'light' ? '#000000' : '#ffffff'};
      }
      .Quote__Slider-Button-Circle {
        width: 56px;
        height: 56px;
        border-radius: 100%;
        box-sizing: border-box;
        border: 2px solid ${$buttonTheme === 'light' ? '#000000' : '#ffffff'};
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        ${theme.breakpoints.down('md')} {
          width: 48px;
          height: 48px;
        }
        svg {
          ${arrowStyles}
        }
      }
    `
    }}
  }
`
