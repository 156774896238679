export const getMicrocopyData = (
  microcopyset: any,
  number: number,
  ctaType: string,
  microcopyParameter: string
) => {
  let results = []

  if (microcopyset.values && Array.isArray(microcopyset.values)) {
    results = microcopyset.values.filter((microcopy: any) => {
      if (microcopy.key && typeof microcopy.key === 'string') {
        let items = microcopy.key.split('.')
        return (
          items.length &&
          items.length >= 2 &&
          (items[1] == number || items[2] == number) &&
          items.includes(`${ctaType}CTA`) &&
          items.includes(microcopyParameter)
        )
      }
      return false
    })
  }
  return results.length ? results : [{ value: 'Read more' }]
}

export const getCustomerStoryMicrocopy = (
  microcopyset: any,
  microcopyType: string,
  microcopyParameter: string
) => {
  let results
  if (microcopyset.values && Array.isArray(microcopyset.values)) {
    results = microcopyset.values.filter((microcopy: any) => {
      if (microcopy.key && typeof microcopy.key === 'string') {
        return (
          microcopy.key.includes(microcopyType) &&
          microcopy.key.includes(microcopyParameter)
        )
      }
      return false
    })
  }
  return results.length ? results : [{ value: '' }]
}

// find {key, value} in microcopy/object
export const findTarget = (
  target: string,
  generics: { key: string; value: any }[]
) => {
  const found = generics.find(
    (generic: { key: string }) => generic.key === target
  )
  return found ? found.value : ''
}
