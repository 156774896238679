// Libraries
import React from 'react'
import moment from 'moment'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

// Styles
import { FeaturedResourceContainer } from './featuredResource'

// Components
import GlobalContainer from '../globalContainer'
import Button from '../button'
import Typography from '../typography'

// Helpers
import ImgUtil from '../../../utils/img.utils'
import options from '../../../utils/richText.utils'
import { getMicrocopyData } from '../../../utils/microcopy.utils'

type imageProps = {
  id: string
  __typename: string
  altText?: string
  image: {
    file: {
      url: string
    }
  }
}

type FeaturedResourceProps = {
  data: {
    __typename: string
    id: string
    name?: string
    seeAllCta?: boolean
    theme?: string
    card: {
      title?: string
      body?: any
      category: {
        number: number
        tinyFeaturedIcon?: imageProps
        title?: string
      }
      image?: imageProps
      companyColorLogo?: imageProps
      companyLogo?: imageProps
      inEnglish?: boolean
      name?: string
      date?: string
      url1?: string
      microcopy: {
        key: string
        value: any
      }
    }
  }
}

const FeaturedResource = (props: FeaturedResourceProps) => {
  if (props.data) {
    const resourceData = props.data
    const { card } = resourceData
    const cardCategory = card && card.category ? card.category : ''

    const theme = resourceData.theme
      ? resourceData.theme.toLowerCase()
      : 'light'
    const seeAllCta =
      resourceData && resourceData.seeAllCta ? resourceData.seeAllCta : false
    const mainImage = card && card.image ? card.image : ''
    const primaryCTAURL = card.url1 ? card.url1 : '/'
    let mainImageSrc = '/'
    let mainImageAlt = 'UiPath'
    if (mainImage) {
      if (mainImage.image && mainImage.image.file && mainImage.image.file.url) {
        mainImageSrc = mainImage.image.file.url
      }
      if (mainImage.altText) {
        mainImageAlt = mainImage.altText
      }
    }

    const cardBody = card.body ? renderRichText(card.body, options) : ''
    const categoryIconSrc =
      cardCategory &&
      cardCategory.tinyFeaturedIcon &&
      cardCategory.tinyFeaturedIcon.image &&
      cardCategory.tinyFeaturedIcon.image.file &&
      cardCategory.tinyFeaturedIcon.image.file.url
        ? cardCategory.tinyFeaturedIcon.image.file.url
        : '/'
    const categoryIconAlt =
      cardCategory &&
      cardCategory.tinyFeaturedIcon &&
      cardCategory.tinyFeaturedIcon.altText
        ? cardCategory.tinyFeaturedIcon.altText
        : 'UiPath Image'
    const eyebrow = cardCategory && cardCategory.title ? cardCategory.title : ''
    const heading = card.title ? card.title : ''
    const microcopyset = card.microcopy
    const number =
      cardCategory && cardCategory.number ? cardCategory.number : ''
    const companyLogoWhiteSrc =
      card &&
      card.companyLogo &&
      card.companyLogo.image &&
      card.companyLogo.image.file &&
      card.companyLogo.image.file.url
        ? card.companyLogo.image.file.url
        : ''

    const companyLogoColorSrc =
      card &&
      card.companyLogo &&
      card.companyLogo.image &&
      card.companyLogo.image.file &&
      card.companyLogo.image.file.url
        ? card.companyLogo.image.file.url
        : ''

    const companyLogoWhiteAlt =
      card && card.companyLogo && card.companyLogo.altText
        ? card.companyLogo.altText
        : 'UiPath Partner Logo'
    const companyLogoColorAlt =
      card && card.companyColorLogo && card.companyColorLogo.altText
        ? card.companyColorLogo.altText
        : 'UiPath Partner Logo'

    let primaryCtaTextVal, secondaryCtaTextVal, secondaryCtaUrlVal
    let primaryCta,
      secondaryCtaText,
      secondaryCtaUrl: any = ''
    if (microcopyset && number) {
      primaryCta = getMicrocopyData(microcopyset, number, 'primary', 'text')
      secondaryCtaText = getMicrocopyData(
        microcopyset,
        number,
        'secondary',
        'text'
      )
      secondaryCtaUrl = getMicrocopyData(
        microcopyset,
        number,
        'secondary',
        'url'
      )
    }
    if (primaryCta && primaryCta[0] && primaryCta[0].value) {
      primaryCtaTextVal = primaryCta[0].value
    }
    if (secondaryCtaText && secondaryCtaText[0] && secondaryCtaText[0].value) {
      secondaryCtaTextVal = secondaryCtaText[0].value
    }
    if (secondaryCtaUrl && secondaryCtaUrl[0] && secondaryCtaUrl[0].value) {
      secondaryCtaUrlVal = secondaryCtaUrl[0].value
    }

    let date = card && card.date ? card.date : null

    let convertedDate
    if (date) {
      convertedDate = new Date(date)
      date = moment(convertedDate).format('MMMM D, YYYY')
    }

    //7 === Webinar
    //11 === Events
    let ctaText, when
    if (
      number &&
      (number === 7 || number === 11) &&
      primaryCta &&
      primaryCta.length >= 2 &&
      convertedDate
    ) {
      let now = new Date()
      when = convertedDate >= now ? 'before' : 'after'
      if (primaryCta[0].key && primaryCta[0].key.includes(when))
        ctaText = primaryCta[0].value
      else if (primaryCta[1].key && primaryCta[1].key.includes(when))
        ctaText = primaryCta[1].value
    }

    // If not webinar, get the first cta text value
    if (!ctaText && primaryCta && primaryCta[0] && primaryCta[0].value)
      ctaText = primaryCta[0].value

    return (
      <FeaturedResourceContainer
        resourceTheme={theme}
        data-cy="FeaturedResource"
      >
        <GlobalContainer>
          <div className="FeaturedResource__Inner">
            <div className="FeaturedResource__Image-Graphic-Outer">
              <div className="FeaturedResource__Image-Container">
                {mainImageSrc && (
                  <img
                    className="FeaturedResource__Image"
                    loading="lazy"
                    alt={mainImageAlt}
                    src={ImgUtil.getResizedImageUrl(mainImageSrc, 'width=282')}
                  />
                )}
                <div className="FeaturedResource__Lower-Icon-Container">
                  <img
                    className="FeaturedResource__Lower-Icon"
                    loading="lazy"
                    alt={categoryIconAlt}
                    src={ImgUtil.getResizedImageUrl(categoryIconSrc, '')}
                  />
                </div>
                {(companyLogoWhiteSrc || companyLogoColorSrc) && (
                  <div className="FeaturedResource__Partner-Logo-Container">
                    <img
                      className="FeaturedResource__Partner-Logo"
                      loading="lazy"
                      alt={
                        companyLogoWhiteAlt
                          ? companyLogoWhiteAlt
                          : companyLogoColorAlt
                      }
                      src={ImgUtil.getResizedImageUrl(
                        companyLogoWhiteSrc
                          ? companyLogoWhiteSrc
                          : companyLogoColorSrc,
                        ''
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="FeaturedResource__Inner-Text">
              {eyebrow && (
                <Typography
                  className="FeaturedResource__Eyebrow"
                  type="labelAlt"
                >
                  {eyebrow}
                </Typography>
              )}
              {heading && (
                <Typography
                  className="FeaturedResource__Inner-Heading"
                  type="header3"
                >
                  {heading}
                </Typography>
              )}
              {cardBody && (
                <Typography
                  type="body"
                  className="FeaturedResource__Inner-Body RichText"
                >
                  {cardBody}
                </Typography>
              )}
              <div className="FeaturedResource__CTA-Container">
                {primaryCTAURL && (
                  <Button
                    icon={true}
                    href={primaryCTAURL}
                    text={ctaText ? ctaText : ''}
                    type="secondary"
                    size="small"
                    className="FeaturedResource__CTA-1"
                    theme={theme}
                  />
                )}
                {seeAllCta && secondaryCtaUrlVal && (
                  <Button
                    icon={true}
                    href={secondaryCtaUrlVal}
                    text={secondaryCtaTextVal ? secondaryCtaTextVal : ''}
                    type="text-link"
                    size="small"
                    className="FeaturedResource__CTA-2"
                    theme={theme}
                  />
                )}
              </div>
            </div>
          </div>
        </GlobalContainer>
      </FeaturedResourceContainer>
    )
  }

  return null
}

export default FeaturedResource
