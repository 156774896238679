import React, { useRef, useState } from 'react'
import { QuoteOuter, QuoteInner } from './quote'
import Slider from 'react-slick'

import Typography from '../typography'
import Button from '../button'
import { SliderOuter } from '../slider/slider'

import ImgUtil from '../../../utils/img.utils'
import QuoteBgImage from './quoteBgImage'
import { internalUrl, internalLinkHandler } from '../../../utils/link.utils' 

const QuoteSlider = (props: any) => {
  const customSlider = useRef<HTMLDivElement>()
  const panels = props.data.panels ? props.data.panels : []

  const [slideNumber, setSlideNumber] = useState(0)
  const imageContainer = useRef<HTMLDivElement>()

  const sliderTheme = props.data.sliderTheme ? props.data.sliderTheme : 'Light'
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => {
      if (
        imageContainer &&
        imageContainer.current &&
        imageContainer.current.children
      ) {
        imageContainer.current.children[slideNumber] &&
          imageContainer.current.children[slideNumber].classList.remove(
            'Quote__Image--Active'
          )

        imageContainer.current.children[slideNumber] &&
          imageContainer.current.children[next].classList.add(
            'Quote__Image--Active'
          )
      }
      setSlideNumber(next)
    },
  }

  const handleSlideNext = () => {
    if (typeof customSlider !== undefined && customSlider.current) {
      // customSlider.current.slickNext()
      customSlider.current.slickGoTo((slideNumber + 1) % panels.length)
    }
  }

  const handleSlidePrev = () => {
    if (typeof customSlider !== undefined && customSlider.current) {
      // customSlider.current.slickPrev()
      customSlider.current.slickGoTo(
        (slideNumber - 1 < 0 ? panels.length - 1 : slideNumber - 1) %
          panels.length
      )
    }
  }

  const renderImages = () => {
    const image = []
    if (panels.length) {
      for (let i = 0; i < panels.length; i++) {
        if (panels[i] && panels[i].image) {
          let altText = panels[i].image.altText ? panels[i].image.altText : ''
          let imageUrl =
            panels[i].image.image &&
            panels[i].image.image.file &&
            panels[i].image.image.file.url
              ? panels[i].image.image.file.url
              : ''
          if (imageUrl)
            image.push(
              <img
                loading="lazy"
                {...(i === 0
                  ? { className: 'Quote__Image Quote__Image--Active' }
                  : { className: 'Quote__Image' })}
                src={ImgUtil.getResizedImageUrl(imageUrl, 'width=282')}
                alt={altText}
              />
            )
        }
      }
    }
    return image
  }

  const renderSlides = () => {
    const slides = []
    if (panels.length) {
      for (let i = 0; i < panels.length; i++) {
        if (panels[i]) {
          const authorName = panels[i].authorName ? panels[i].authorName : ''
          const authorTitle = panels[i].authorTitle ? panels[i].authorTitle : ''
          const company = panels[i].company ? panels[i].company : ''
          const body =
            panels[i].body && panels[i].body.body ? panels[i].body.body : ''

          const quoteCtaText =
            panels[i].quoteCta && panels[i].quoteCta.text
              ? panels[i].quoteCta.text
              : ''
          const quoteLinkUrl =
            panels[i].quoteCta && panels[i].quoteCta.externalUrl
              ? panels[i].quoteCta.externalUrl
              : ''
              

          slides.push(
            <div className="Quote__Slider-Item">
              {body && <Typography type="leadXS">{body}</Typography>}
              {authorName && (
                <Typography className="Quote__Author" type="label">
                  {authorName}
                </Typography>
              )}
              {authorTitle && company && (
                <Typography
                  className="Quote__Title"
                  type="bodyXS"
                >{`${authorTitle}, ${company}`}</Typography>
              )}
              {quoteCtaText && quoteLinkUrl && (
                <Button
                  icon={true}
                  href={
                    panels[i].quoteCta.internalLink
                      ? internalLinkHandler(panels[i].quoteCta.internalLink)
                      : quoteLinkUrl
                  }
                  text={quoteCtaText}
                  type="text-link"
                  size="small"
                  className="Quote__CTA"
                  theme={sliderTheme === 'Dark' ? 'dark' : 'light'}
                />
              )}
            </div>
          )
        }
      }
    }
    return slides
  }

  return (
    <QuoteOuter quoteTheme={sliderTheme} data-cy="QuoteSlider">
      <QuoteInner className="Quote__Slider">
        <QuoteBgImage />
        <div className="Quote__Inner">
          <div ref={imageContainer} className="Quote__Image-Container">
            {renderImages()}
          </div>
          <div className="Quote__Text">
            <div className="Quote__Slider-Nav-Container">
              <Button
                type="icon"
                clickHandler={handleSlidePrev}
                className="Quote__Slider-Prev-Button"
                ariaLabel="Previous slide"
                arrowOrientation="left"
                theme={sliderTheme.toLowerCase()}
              />

              <div className="Quote__Slider-Slide-Position">
                <Typography type="leadXS">
                  {slideNumber + 1} / {panels.length}
                </Typography>
              </div>

              <Button
                type="icon"
                clickHandler={handleSlideNext}
                className="Quote__Slider-Next-Button"
                ariaLabel="Next slide"
                arrowOrientation="right"
                theme={sliderTheme.toLowerCase()}
              />
            </div>

            <SliderOuter>
              <Slider
                {...settings}
                ref={(slider: any) => (customSlider.current = slider)}
              >
                {renderSlides()}
              </Slider>
            </SliderOuter>
          </div>
        </div>
      </QuoteInner>
    </QuoteOuter>
  )
}

export default QuoteSlider
