import React, { useState } from 'react'
import {
  PlayButton,
  VideoImageContainer,
  VideoImage,
  VideoOverlay,
} from './styles'

import Backdrop from '@material-ui/core/Backdrop'
import ImgUtil from '../../../utils/img.utils'
import DottedModal from '../dottedModal'

/// video player code thats pretty solid - sukhi
/// only needs the video ID

type VideoPlayerProps = {
  videoId: string
  width: string
  height: string
  img: string
  type?: string
  start?: string
  backgroundAltText?: string
  backgroundImgParams?: string
  outerPage?: string
}

const VideoPlayer = ({
  videoId,
  width,
  height,
  img,
  type = 'youtube',
  start,
  backgroundAltText = '',
  backgroundImgParams = '',
  outerPage = '',
}: VideoPlayerProps) => {
  const vidWidth = width === '100%' ? '100%' : `${width}px`

  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const url: { [key: string]: string } = {
    youtube: 'https://www.youtube.com/embed',
    vimeo: 'https://player.vimeo.com/video',
  }

  let src = `${url[type]}/${videoId}?autoplay=1`
  src += start ? `&start=${start}` : ''

  return (
    <React.Fragment>
      <DottedModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        handleClose={handleClose}
        closeAfterTransition
        type="video"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <iframe
          width="100%"
          height={height || '475'}
          src={src}
          frameBorder="0"
          allowFullScreen
          data-cy={`VideoPlayer-Modal--${type}`}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
      </DottedModal>
      <VideoImageContainer $outerPage={outerPage}>
        <VideoImage
          onClick={handleOpen}
          data-cy="VideoPlayer-Image"
          $outerPage={outerPage}
        >
          <img
            src={ImgUtil.getResizedImageUrl(img, backgroundImgParams)}
            alt={backgroundAltText}
            className="VideoPlayer__Image-File"
          />
          <PlayButton />
          <VideoOverlay />
        </VideoImage>
      </VideoImageContainer>
    </React.Fragment>
  )
}
export default VideoPlayer
